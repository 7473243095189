
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class Customers {

    constructor() {

    }


    async getCustomersHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/admin/customers/get-customers', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async approveCustomerHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/admin/customers/approve-customer', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }
    }
    async rejectCustomerHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/admin/customers/reject-customer', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }
    }
    async activateSubscriptionHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/admin/customers/activate-subscribtion', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }
    }


}

export default Customers;