import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'

/*Redux*/
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'
import ReverseProxy from '../../config/reverseProxy'

import AdminUserHandler from '../AdminUser/AdminUser'

import jwt_decode from 'jwt-decode';

import SystemToastPopup from '../../components/SystemToastPopup'
import Icons from '../../assets/Icons'

const AuthTokenValidation = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Authendication')


    const adminUserHandler = new AdminUserHandler()

    const navigate = useNavigate();

    const store = useSelector((store) => store)
    const dispatch = useDispatch()

    const { updateState } = new UserAction

    const setUserDetials = async () => {

        let response = await adminUserHandler.getProfileHandler()

        if (response && response.success) {

            let {
                email, id, name, role_detials, user_type, profile_img, phone
            } = response.data

            let userdetials = {
                email, id, name, profile_img, phone
            }
            localStorage.setItem('userdetials', JSON.stringify(userdetials))

            dispatch(updateState({
                type: "SET_USER",
                payload: {
                    isAuthenticated: true,
                    user: {
                        ...userdetials,
                        user_type
                    },
                    roles_permissions: role_detials || {},
                }
            }))

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in User detials`)
        }

    }

    const HandleEvents = () => {

        const authToken = localStorage.getItem('authToken');
        const userdetials = store.user.user || {}

        const url = window.location.pathname
        const Auth_Paths = ['/signin', '/signup', '/forget-password', '/verify-reset-password', '/reset-password']


        const checkURLValid = (url) => {
            for (const itm of Auth_Paths) if (url.includes(itm)) return true
            return false
        }

        if (authToken && checkURLValid(url)) {

            return navigate(`${ReverseProxy['proxyUrl']}/admin/quick-menu`);

        }

        if (!authToken && checkURLValid(url)) {
            return
        }

        if (!authToken) {

            navigate(`${ReverseProxy['proxyUrl']}/admin/signin`);
            return;
        }

        setUserDetials()

        try {
            const decoded = jwt_decode(authToken);
            const currentTimestamp = Math.floor(Date.now() / 1000);

            if (decoded.exp < currentTimestamp) {

                localStorage.removeItem('authToken');

                navigate(`${ReverseProxy['proxyUrl']}/admin/signin`);
            }
        } catch (error) {
            console.error('Error decoding authToken:', error);
        }
    }

    useEffect(() => {
        HandleEvents()

    }, []);


    useEffect(() => {
        const url = window.location.pathname
        const userdetials = store.user.user || {}

        // if (url.includes('/onboarding')) return navigate(`${ReverseProxy['proxyUrl']}/admin/quick-menu`);

    }, [store])
    return (
        <>
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Authendication",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />
                : null}
        </>
    );
};

export default AuthTokenValidation;