

import React, { useEffect, useState, useRef, Fragment } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import CustomersHandler from '../../Handlers/Customers/Customers';
import SubscriptionPlansHandler from '../../Handlers/SubscriptionPlans/SubscriptionPlans';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'



const ViewSubscriptionPlan = ({ props }) => {

    const { planId } = useParams();

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['user'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--vendors' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    const [ApprovalStatusAction, setApprovalStatusAction] = useState('')
    const [ApprovalStatusAlert, setApprovalStatusAlert] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/admin/quick-menu`, { state: { from: location }, replace: true })
            }
            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const subscriptionPlansHandler = new SubscriptionPlansHandler()

    const HandleCancel = (e) => {

        navigate('/admin/app/subscription-plans')
    }



    const [PlanName, setPlanName] = useState('N/A')
    const [PlanType, setPlanType] = useState('N/A')
    const [PlanCategory, setPlanCategory] = useState('N/A')
    const [PlanAmount, setPlanAmount] = useState('N/A')
    const [PlanUsers, setPlanUsers] = useState('N/A')
    const [PlanOffer, setPlanOffer] = useState('N/A')
    const [PlanStatus, setPlanStatus] = useState('N/A')
    const [TotalPlanModules, setTotalPlanModules] = useState('N/A')
    const [PlanModules, setPlanModules] = useState([])

    const [PlanLastUpdated, setPlanLastUpdated] = useState('Last Update : 04:59 PM')


    const LoadPlan = async () => {

        let filters = {
            id: planId
        }

        let response = await subscriptionPlansHandler.getPlansHandler(filters)

        if (response && response.success && Array.isArray(response.data) && response.data.length) {

            let plan = response.data[0]

            if (!plan) return navigate('/admin/app/subscription-plans')

            setPlanName(plan.plan_name)

            let plan_type = plan.type == '0' ? 'Free' : plan.type == '1' ? 'Paid' : plan.type == '2' ? 'Customized' : 'N/A'
            let plan_category = plan.plan_category == '0' ? 'Builder Plan' : plan.plan_category == '1' ? 'Interior Designer' : 'N/A'
            let plan_amount = `₹ ${parseFloat(plan.current_price || 0).toFixed(2)}`
            let plan_offer = plan.offer_percent ? `${parseFloat(plan.offer_percent || 0).toFixed(2)}` : 'N/A'
            let status = plan.is_active == '0' ? 'Inactive' : plan.is_active == '1' ? 'Active' : 'N/A'
            let lastupdated = `Last Updated : ${Utils._getLocalFullDate(plan.updated_at || plan.created_at)}`

            setPlanType(plan_type)
            setPlanCategory(plan_category)
            setPlanAmount(plan_amount)
            setPlanUsers(plan.team_users_count)
            setPlanOffer(plan_offer)
            setPlanStatus(status)
            setTotalPlanModules(Array.isArray(plan.modules) ? plan.modules.length : 0)
            setPlanModules(plan.modules || [])
            setPlanLastUpdated(lastupdated)

            console.log(plan, 'customer');



        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in getting customer detials`)
        }
    }

    const HandleEditPlan = () => {

        navigate(`/admin/app/edit-subscription-plan/${planId}`)

    }



    useEffect(() => {

        LoadPlan()

    }, [])



    return (
        <>



            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Vendors",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}





            <div className="customer-view-main">

                <div className="customer-content-main">

                    <div className="customer-section-main customer-header-main">

                        <div className=" header-content">
                            <div className="header-left">
                                <div className="header-back-btn"
                                    onClick={(e) => HandleCancel(e)}
                                    dangerouslySetInnerHTML={{ __html: Icons.general.back_arrow }}
                                ></div>
                                <div className="header-detials">
                                    <div className="header-name">{PlanName}</div>

                                    <div className="header-id">ID: {planId}</div>
                                    <div className="header-lastupdated">{PlanLastUpdated}</div>
                                </div>
                            </div>
                            <div className="header-right">
                                <div
                                    className="header-action-btn header-quick-btns header-edit-btn"
                                    onClick={(e) => HandleEditPlan(e)}
                                >
                                    <div className="label">Edit</div>
                                </div>
                                <div className="header-action-btn header-option-btn">
                                    <div className="icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.more_btn }}
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="customer-section-main customer-detials-main">

                        <div className="section-content">

                            <div className="section-header">
                                <div className="title">Plan Detials</div>
                            </div>

                            <div className="detials-content-main">

                                <div className="detials-content-section">


                                    <div className="detials-content-row">
                                        <div className="label">Plan Name</div>
                                        <div className="value">{PlanName}</div>
                                    </div>

                                    <div className="detials-content-row">
                                        <div className="label">Plan Type</div>
                                        <div className="value">{PlanType}</div>
                                    </div>
                                    <div className="detials-content-row">
                                        <div className="label">Plan Category</div>
                                        <div className="value">{PlanCategory}</div>
                                    </div>
                                    <div className="detials-content-row">
                                        <div className="label">Plan Amount</div>
                                        <div className="value">{PlanAmount}</div>
                                    </div>
                                    <div className="detials-content-row">
                                        <div className="label">Plan Users</div>
                                        <div className="value">{PlanUsers}</div>
                                    </div>
                                </div>
                                <div className="detials-content-section">


                                    <div className="detials-content-row">
                                        <div className="label">Plan Offer</div>
                                        <div className="value">{PlanOffer}</div>
                                    </div>
                                    <div className="detials-content-row">

                                        <div className="label">Plan Status</div>
                                        <div className="value">{PlanStatus}</div>
                                    </div>
                                    <div className="detials-content-row">
                                        <div className="label">Total Plan Modules</div>
                                        <div className="value">{TotalPlanModules}</div>
                                    </div>




                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="customer-section-main customer-detials-main">

                        <div className="section-content">

                            <div className="section-header">
                                <div className="title">Plan Features</div>

                            </div>

                            <div className="detials-content-main">

                                <div className="detials-content-section">
                                    {
                                        PlanModules.slice(0, PlanModules.length / 2).map((module, index) => (

                                            <div
                                                className="detials-content-row detials-feature-row"
                                                key={`module-${module.feature_key}`}
                                            >

                                                <div className="value" style={{ width: '400px' }}>
                                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.arrow_right }}></div>
                                                    {module.feature_name}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="detials-content-section">
                                    {
                                        PlanModules.slice(PlanModules.length / 2, PlanModules.length).map((module, index) => (

                                            <div
                                                className="detials-content-row detials-feature-row"
                                                key={`module-${module.feature_key}`}
                                            >
                                                <div className="value" style={{ width: '400px' }}>
                                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.arrow_right }}></div>
                                                    {module.feature_name}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>


                        </div>
                    </div>


                </div>
            </div >
        </>
    )

}

export default ViewSubscriptionPlan;