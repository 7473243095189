

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import SubscriptionPlansHandler from '../../Handlers/SubscriptionPlans/SubscriptionPlans';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import SmarkTopCards from '../../components/SmarkTopCards'
import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const SubscriptionPlans = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const subscriptionPlansHandler = new SubscriptionPlansHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")


    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--vendors', feature_key: 'vendors' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/app/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/admin/quick-menu`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [SubscriptionPlansDetials, setSubscriptionPlansDetials] = useState([])
    const [selectedAction, setSelectedAction] = useState('')

    const [ActionDropdown, setActionDropdown] = useState([
        {
            id: 'edit',
            label: 'View',
            icon: Icons.general.open_link,
        },
    ])


    const tabelHeader = [

        {
            id: 'plan_name',
            label: 'Plan Name',

        },
        {
            id: 'modules',
            label: 'Modules',
            format: (value) => Array.isArray(value) ? value.length : 0,
        },
        {
            id: 'current_price',
            label: 'Price',
            format: (value) => value ? `₹${parseInt(value || 0)}` : 'N/A',
        },
        {
            id: 'offer_percent',
            label: 'Offer',
            format: (value) => value ? `${parseInt(value || 0)}%` : 'N/A',
        },

        {
            id: 'plan_category',
            label: 'Plan Category',
            format: (value) => {
                if (value == "0") return "Builder"
                else if (value == "1") return "Interior Designer"
            },
        },
        {
            id: 'type',
            label: 'Type',
            format: (value) => {
                if (value == "0") return "Free plan"
                else if (value == "1") return "Paid plan"
                else if (value == "2") return "Customized plan"
            },
        },
        {
            id: 'is_active',
            label: 'Status',
            format: (value) => {
                if (value == "0") return "Inactive"
                else if (value == "1") return "Active"
            },
        },

        {
            id: 'actions',
            label: 'Actions',
            minWidth: 50,
            align: 'right',
            format: (value) => value,
        },
    ];

    const getSubscriptionPlansDetials = async () => {

        let response = await subscriptionPlansHandler.getPlansHandler()

        if (response && response.success) {

            let plans = response.data || []

            console.log(plans, 'plans');

            setSubscriptionPlansDetials(plans)

            HandleSmartCardSetup(plans)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in getting vendor detials`)
        }
    }


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const HandleViewCustomer = (plan) => {
        if (!has_view_access) return
        navigate(`/admin/app/view-subscription-plan/${plan.id}`)

    }

    const HanelCellAction = (id, row) => {

        if (id == "plan_name") HandleViewCustomer(row)
        if (id == "actions") {
            setSelectedAction(row.id)
            HandleActionDisable(row.id)
        }
    }

    const HandleActionDisable = (id) => {

        let input = document.getElementById(`clients-tabel-action-btn-${id}`)
        let dropdown_items = document.getElementById(`clients-tabel-action-dropdown-${id}`)

        const Event = (e) => {
            let path = e.path || (e.composedPath && e.composedPath());

            if (Array.isArray(path) && !path.includes(dropdown_items) && !path.includes(input)) {
                setSelectedAction('')
                window.removeEventListener('click', Event)
            }
        }

        window.addEventListener('click', Event)
    }

    const HandleAddSubscriptionPlan = () => {
        navigate(`/admin/app/add-subscription-plan`)
    }

    useEffect(() => {
        getSubscriptionPlansDetials()
    }, [])

    const [SmarkCard_Items, setSmarkCard_Items] = useState([])

    const HandleSmartCardClick = (id) => { }

    const HandleSmartCardSetup = (items) => {

        items = items || []

        let total = items.length
        let total_active = items.filter(itm => itm.is_active == "1").length
        let total_inactive = items.filter(itm => itm.is_active == "0").length

        let smark_cards = [
            {
                id: 'lm-smart-top-cards-total',
                label: 'Total Plans',
                value: total || "0",
                icon: Icons.general.bills,
                unit_name: '',
                style: {
                    color: '#0065ff',
                    bg_linear_colors: {
                        start: '#e5efff',
                        end: '#e5f9ff'
                    },
                    border_color: '#c6d4dc',
                },
            },
            {
                id: 'lm-smart-top-cards-total_active',
                label: 'Active Plans',
                value: total_active || "0",
                icon: Icons.general.bills,
                unit_name: '',
                style: {
                    color: '#33c87e',
                    bg_linear_colors: {
                        start: '#eaf9f2',
                        end: '#f2fef7'
                    },
                    border_color: '#ccd8d2',
                },
            },
            {
                id: 'lm-smart-top-cards-total_inactive',
                label: 'Inactive Plans',
                value: total_inactive || "0",
                icon: Icons.general.bills,
                unit_name: '',
                style: {
                    color: '#ff5b5b',
                    bg_linear_colors: {
                        start: '#ffeded',
                        end: '#fff9f9'
                    },
                    border_color: '#dcd1d1',
                },
            },
        ]

        setSmarkCard_Items(smark_cards)

    }

    return (
        <>
            <div className="project-clients-main">
                <div className="clients-panel-content">


                    {SmarkCard_Items.length ?
                        <SmarkTopCards props={{
                            id: 'labourManagement-smart-top-cards',
                            cards: SmarkCard_Items || [],
                            callback: HandleSmartCardClick
                        }} />
                        : ''}
                    <div className="smart-panel-content-main">
                        <div className="panel-content-header">
                            <div className="title">Subscription Plans</div>
                            <div className="buttons">
                                {has_add_access ?
                                    <div
                                        className="button add-button"
                                        onClick={(e) => HandleAddSubscriptionPlan()}
                                    >
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_active }}></div>
                                        <div className="label">Add New</div>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                        <div className="panel-content-sections panel-content-tabel-section">
                            <Paper style={{ width: '100%', height: '100%', boxShadow: 'none', overflow: 'hidden' }}>
                                <TableContainer style={{ height: '93%', boxShadow: 'none !important', }}>
                                    <Table stickyHeader>
                                        <TableHead
                                            className='tabel-header'
                                        >
                                            <TableRow>
                                                {tabelHeader.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                        className='tabel-header-items'
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className='tabel-body'>
                                            {SubscriptionPlansDetials
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                            {tabelHeader.map((column) => {
                                                                const value = row[column.id];
                                                                return (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}
                                                                        onClick={(e) => {
                                                                            if (column.id != 'actions') HanelCellAction(column.id, row)
                                                                        }}
                                                                    >
                                                                        {
                                                                            column.id == 'plan_name' ?
                                                                                <div
                                                                                    className="tabel-name"
                                                                                >
                                                                                    {column.format ? column.format(value) : value}
                                                                                </div>

                                                                                : column.id == 'actions' && has_view_access ?

                                                                                    <div
                                                                                        className="tabel-action-main"
                                                                                    >
                                                                                        <div
                                                                                            className="action-icon clients-tabel-action-btn"
                                                                                            id={`clients-tabel-action-btn-${row.id}`}
                                                                                            dangerouslySetInnerHTML={{ __html: Icons.general.dotted_action }}
                                                                                            onClick={(e) => HanelCellAction(column.id, row)}
                                                                                        ></div>
                                                                                        <div
                                                                                            className={`action-dropdown clients-tabel-action-dropdown ${selectedAction == row.id ? 'action-dropdown-active' : ''}`}
                                                                                            id={`clients-tabel-action-dropdown${row.id}`}
                                                                                        >
                                                                                            {ActionDropdown.map(option => (

                                                                                                !has_view_access ? '' :
                                                                                                    <div
                                                                                                        className="action-dropdown-item"
                                                                                                        key={`proposals-action${option.id}`}
                                                                                                        onClick={(e) => {
                                                                                                            setSelectedAction('')
                                                                                                            if (option.id == 'edit') HandleViewCustomer(row)

                                                                                                        }}
                                                                                                    >
                                                                                                        <div
                                                                                                            className="icon"
                                                                                                            dangerouslySetInnerHTML={{ __html: option.icon }}
                                                                                                        ></div>
                                                                                                        <div className="label">{option.label}</div>
                                                                                                    </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    : column.format ? column.format(value) : value
                                                                        }
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    style={{ height: '7%', overflow: 'hidden' }}
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={SubscriptionPlansDetials.length || 0}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default SubscriptionPlans;