
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class AdminUser {

    constructor() {

    }

    async getProfileHandler() {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/admin/auth/get-profile', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

    async updateProfileHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/admin/update-profile-detials', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isdefault: false, isToken: true }),
                body: params
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

}

export default AdminUser;