import React, { useEffect, useState, useRef, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import StoreHandler from '../redux/StoreHandler'
import UserAction from '../redux/action/userAction'
/* Redux Setup*/
import store from '../redux/store'
import { Link, useNavigate } from 'react-router-dom'

import Header from './header/header'
import Image from '../assets/Images'
import Icons from '../assets/Icons'


import { useAuth, useMenuAuth } from '../hooks/useAuth'


const QuickMenus = () => {

    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['user'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const QUICK_MENUS = [

        {
            id: 'project_smart_analytics',
            label: "Smart Analytics",
            key: 'project_dashboard',
            icon: Icons.general.approve,
            description: "(Dashboard, Profit/Loss, Payables)",
            callback: (e) => {
                navigate('/admin/app/projects/PR-001/dashboard')
            },
            background: '#e0f7fa',
            color: '#21abbc'
        },
        {
            id: 'projects',
            label: "Projects",
            key: 'projects',
            icon: Icons.general.takeoff,
            description: "(Project Details, Status)",
            callback: (e) => {
                navigate('/admin/app/projects')
            },
            background: '#e3f2fd',
            color: 'rgb(66, 165, 245)'
        },
        {
            id: 'pre_construction',
            label: "Pre Construction",
            key: 'project_estimate',
            icon: Icons.general.estimate,
            description: "(Estimation, Proposals, BOQs)",
            callback: (e) => {
                navigate('/admin/app/projects/PR-001/estimate')
            },
            background: '#e0f7fa',
            color: '#21abbc'
        },
        {
            id: 'purchase_orders',
            label: "Purchases",
            key: 'purchase_orders',
            icon: Icons.general.purchase_order,
            description: "(Purchase Orders, GRNs, Bills)",
            callback: (e) => {
                navigate('/admin/app/projects/PR-001/purchase_order')
            },
            background: '#deecff',
            color: '#0060df'
        },
        {
            id: 'inventory',
            label: "Inventory",
            key: 'stock_items',
            icon: Icons.general.inventory,
            description: "(Stocks, Indents, Issues, Deliveries)",
            callback: (e) => {
                navigate('/admin/app/projects/PR-001/inventory/stock-items')
            },
            background: 'rgb(255, 243, 224)',
            color: 'rgb(255, 167, 38)'
        },
        {
            id: 'finance',
            label: "Finance",
            icon: Icons.general.budget,
            description: "(Cashflow, Estimated Vs Actuals)",
            callback: (e) => {
                navigate('/admin/app/projects/PR-001/finance-summary')
            },
            background: 'rgb(232, 234, 246)',
            color: 'rgb(92, 107, 192)'
        },

        {
            id: 'labours',
            label: "Labours",
            key: "all_labours",
            icon: Icons.general.labour,
            description: "(Labour profiles, Labour accounts)",
            callback: (e) => {
                navigate('/admin/app/labours')
            },
            background: '#e0f7fa',
            color: '#21abbc'
        },
        {
            id: 'reports',
            key: 'project_reports',
            label: "Reports",
            icon: Icons.general.report,
            description: "(Progress, Profit/Loss, Payables)",
            callback: (e) => {
                navigate('/admin/app/projects/PR-001/reports/progress-report')
            },
            background: 'rgb(232, 245, 233)',
            color: 'rgb(102, 187, 106)'
        },


        {
            id: 'employees',
            label: "Employees",
            key: "employees",
            icon: Icons.general.user_role,
            description: "(Tracking, Role permissions)",
            callback: (e) => {
                navigate('/admin/app/team-management')
            },
            background: '#fce4ec',
            color: 'rgb(236, 64, 122)'
        },
        {
            id: 'clinets',
            label: "Clinets ",
            key: "clients",
            icon: Icons.general.users_group,
            description: "(Profiles, Status)",
            callback: (e) => {
                navigate('/admin/app/clients')
            },
            background: 'rgb(239, 235, 233)',
            color: 'rgb(141, 110, 99)'
        },
        {
            id: 'vendors',
            label: "Sub/Vendors",
            key: "vendors",
            icon: Icons.general.users_group,
            description: "(Profiles, Status)",
            callback: (e) => {
                navigate('/admin/app/vendors')
            },
            background: 'rgb(251, 233, 231)',
            color: 'rgb(255, 112, 67)'
        },

        {
            id: 'masters',
            label: "Masters",
            key: "work_categories",
            icon: Icons.general.cost_code,
            description: "(Work Category, Schedule Rates, Work Rates)",
            callback: (e) => {
                navigate('/admin/app/work-category')
            },
            background: '#ebf1f2',
            color: '#477c8d'
        },
        {
            id: 'settings',
            label: "My Company",
            key: "settings",
            icon: Icons.general.settings,
            description: "(Profile, Settings, Subscription)",
            callback: (e) => {
                navigate('/admin/app/settings/myprofile')
            },
            background: 'rgb(251, 233, 231)',
            color: 'rgb(255, 112, 67)'
        },


    ]

    const [QuickMenus, setQuickMenus] = useState(QUICK_MENUS)

    const [userDetials, setUserDetials] = useState({})
    const [profileImg_url, setProfileImg_url] = useState('')

    const LogoutEvent = () => {
        try {

            localStorage.removeItem('authToken');
            localStorage.removeItem('userdetials');
            window.location.href = '/app/signin'


        } catch (error) {
            console.error('Error decoding authToken:', error);
        }
    }

    const makeCustomDropDown = (params) => {

        let { id, icon, isItemsLabel, itemsLabel, callback = () => { }, _classList, activeClass, isIcon, isImg, img, isLable, lablel, isOptions, options = [] } = params

        options = options || []
        _classList = _classList || []

        let active_dropdown_class = activeClass || "custom-dropdown-active"

        let container = document.createElement('div')

        _classList.push('custom-dropdown')

        container.classList.add(..._classList)
        container.id = id

        container.innerHTML = `
            <div class="custom-dropdown-label">
                ${isLable ? `<span class='lablel'>${lablel}</span>` : ''}
                ${isImg ? `<span class='img'><img src='${img}'/></span>` : ''}
                ${isIcon ? `<span class='icon'>${icon}</span>` : ''}
            </div>
            <div class="custom-dropdown-items-main">
                <span class='arrow-up'></span>
                ${isItemsLabel ? `<span class='dropdown-items-label'>${itemsLabel}</span>` : ''}
                <div class="custom-dropdown-items" id='custom-dropdown-items'>
                </div>
            </div>
        `

        let makeItem = (option) => {

            let { icon, isIcon, isLeft, lablel, callback } = option

            let item = document.createElement('div')
            item.classList.add('custom-dropdown-item')

            item.innerHTML = `

                <div class="custom-dropdown-item-label">
                    ${isLeft && isIcon ? `<span class='icon'>${icon}</span>` : ''}
                    <span class='lablel'>${lablel}</span>
                    ${!isLeft && isIcon ? `<span class='icon'>${icon}</span>` : ''}
                </div>

            `

            item.addEventListener('click', (e) => {
                callback(e)
                container.classList.remove(active_dropdown_class)
            })

            return item

        }

        let items_parent = container.querySelector('#custom-dropdown-items')

        if (isOptions) {

            options.forEach((opt, i) => {

                let item = makeItem(opt)
                items_parent.appendChild(item)
            })
        }


        let container_label = container.querySelector('.custom-dropdown-label')


        container_label.addEventListener('click', (e) => {

            if (isOptions) container.classList.toggle(active_dropdown_class)

            else callback(e)



        })

        window.addEventListener('click', (e) => {

            let path = e.path || (e.composedPath && e.composedPath());

            if (Array.isArray(path) && !path.includes(items_parent) && !path.includes(container_label))
                container.classList.remove(active_dropdown_class)
        })

        return container
    }

    const CustomDropDown = (dropdown, parentContainer) => {

        let hideContainer = (id, parentContainer) => {

            if (!id || !parentContainer) return false

            if (parentContainer.querySelector(`#${id}`))
                parentContainer.removeChild(parentContainer.querySelector(`#${id}`))

            return true
        }

        hideContainer(dropdown.id, parentContainer)

        let container = makeCustomDropDown(dropdown)
        parentContainer.appendChild(container)
    }

    const HandleDropDownEvents = () => {

        let parentContainer = document.querySelector('#project-header-main')
        let project_dropdown = parentContainer.querySelector('#project-select-dropdown')
        let notification_dropdown = parentContainer.querySelector('#notification-select-dropdown')
        let contact_dropdown = parentContainer.querySelector('#contact-select-dropdown')
        let help_dropdown = parentContainer.querySelector('#help-select-dropdown')
        let feedback_dropdown = parentContainer.querySelector('#feedback-select-dropdown')
        let profile_dropdown = parentContainer.querySelector('#profile-select-dropdown')

        let contacts_data = {
            id: "header-contact-dropdown-input",
            lablel: "Feedback",
            itemsLabel: 'SYSTEM SETTINGS',
            isItemsLabel: false,
            _classList: [],
            activeClass: 'custom-button-dropdown-active-norotate',
            isIcon: true,
            isImg: false,
            img: null,
            isLable: false,
            icon: Icons.general.whatsapp,
            isOptions: false,
            callback: () => {
                window.open('https://wa.link/2ktfa6', '_blank');
            },
            options: [
            ]
        }
        let notification_data = {
            id: "header-feedback-dropdown-input",
            lablel: "Feedback",
            itemsLabel: 'SYSTEM SETTINGS',
            isItemsLabel: false,
            _classList: [],
            activeClass: 'custom-button-dropdown-active-norotate',
            isIcon: true,
            isImg: false,
            img: null,
            isLable: false,
            icon: Icons.general.notification,
            isOptions: false,
            isNotification: true,
            callback: () => { },
            options: [
            ]
        }
        let profile_data = {
            id: "header-profile-dropdown-input",
            lablel: "Build Mate",
            itemsLabel: 'SYSTEM SETTINGS',
            isItemsLabel: false,
            _classList: ['custom-dropdown-img'],
            activeClass: 'custom-button-dropdown-active',
            isIcon: false,
            isImg: true,
            img: profileImg_url || Image.User,
            isLable: false,
            icon: Icons.general.dropdown_arrow,
            isOptions: true,
            callback: () => { },
            options: [
                {
                    icon: Icons.general.user,
                    isIcon: true,
                    isLeft: true,
                    lablel: "My Profile",
                    callback: () => { navigate('/admin/app/settings/myprofile') }
                },
                {
                    icon: Icons.general.settings,
                    isIcon: true,
                    isLeft: true,
                    lablel: "Settings",
                    callback: () => { navigate('/admin/app/settings/myprofile') }
                },
                {
                    icon: Icons.general.logout,
                    isIcon: true,
                    isLeft: true,
                    lablel: "Log out",
                    callback: () => { LogoutEvent() }
                },
            ]
        }
        let feedback_data = {
            id: "header-feedback-dropdown-input",
            lablel: "Feedback",
            itemsLabel: 'SYSTEM SETTINGS',
            isItemsLabel: false,
            _classList: [],
            activeClass: 'custom-button-dropdown-active-norotate',
            isIcon: true,
            isImg: false,
            img: null,
            isLable: false,
            icon: Icons.general.feedback,
            isOptions: false,
            callback: () => {
                navigate('/admin/app/feedback')
            },
            options: [
            ]
        }

        CustomDropDown(contacts_data, contact_dropdown)
        CustomDropDown(notification_data, notification_dropdown)
        CustomDropDown(feedback_data, feedback_dropdown)
        CustomDropDown(profile_data, profile_dropdown)
    }
    useEffect(() => {

        HandleDropDownEvents()
        LoadStoreData()
    }, [])

    useEffect(() => {
        let user = store.user.user
        if (user && Object.keys(user).length > 0) {
            setUserDetials(user)

            if (user.profile_img) setProfileImg_url(`data:image/png;base64,${user.profile_img}`)
        }
        HandleDropDownEvents()

    }, [store])





    return (

        <div className={`project-quickmenu-main  `}>
            <div className='project-quickmenu-content'>

                <div
                    className='project-header-main'
                    id='project-header-main'
                    style={{ height: '80px' }}
                >
                    <div className="header-content">
                        <div className="header-left">

                            <div className="header-left-item">
                                <Link to={'/app/quick-menu'}><img style={{ display: 'flex' }} className='header-logo' src={Image.logo} /></Link>
                            </div>
                        </div>
                        <div className="header-right">

                            <div className="header-right-item" id='contact-select-dropdown'></div>
                            <div className="header-right-item" id='feedback-select-dropdown'></div>
                            <div className="header-right-item" id='notification-select-dropdown'></div>
                            <div className="header-right-item" id='profile-select-dropdown'></div>
                        </div>
                    </div>
                </div>

                <div className="quickmenu-menu-content">
                    <div className="quickmenu-menu-items">
                        {QuickMenus?.map((item, index) => (
                            <div
                                className="menu-item"
                                key={item.id}
                                style={{
                                    background: item.background,
                                    borderColor: item.color
                                }}
                                onClick={item.callback}
                            >
                                <div className="left">
                                    <div className="icon"
                                        style={{
                                            background: item.color,
                                        }}
                                        dangerouslySetInnerHTML={{ __html: item.icon }}
                                    ></div>
                                </div>
                                <div className="right">
                                    <div
                                        className="label"
                                        style={{
                                            color: item.color
                                        }}
                                    >{item.label}</div>
                                    {item.description ? <div className="desc">{item.description}</div> : ''}
                                </div>

                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuickMenus;
