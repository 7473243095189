import React, { useEffect, useState } from 'react'

/* Redux Setup*/
import { Route, Routes, BrowserRouter, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import './assets/css/index.css'

import AdminAppIndex from './AdminAppIndex'

import ReverseProxy from './config/reverseProxy'
import Utils from './utils'

const App = () => {

  const store = useSelector((store) => store)


  return (

    <>
      <BrowserRouter>
        <Routes >
          <Route exact path={`${ReverseProxy['proxyUrl']}/admin/*`} element={<AdminAppIndex />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App;
