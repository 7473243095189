import React, { useEffect, useState } from 'react'

/* Redux Setup*/
import { Link, useNavigate, Route, Routes, BrowserRouter, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import store from '../redux/store'

import ReverseProxy from '../config/reverseProxy'

import Mainsidebar from './sidebar/Mainsidebar'
import Header from './header/header'

import RoleManagement from './roleManagement/roleManagement'
import TeamManagement from './teamManagement/teamManagement'
import Customers from './customers/customers'
import ViewCustomer from './customers/viewCustomer'
import ContactRequests from './contactRequests/contactRequests'
import DemoRequests from './demoRequests/demoRequests'

import SubscriptionPlans from './SubscriptionPlans/SubscriptionPlans'
import ViewSubscriptionPlan from './SubscriptionPlans/ViewSubscriptionPlan'
import EditSubscriptionPlan from './SubscriptionPlans/EditSubscriptionPlan'


import Settings from './settings/settings'


const Admin = () => {

    const store = useSelector((store) => store)
    const navigate = useNavigate()


    useEffect(() => {

    }, [store])

    return (

        <div className={`project-section-container`}>
            <Mainsidebar />
            <div className='project-content-main'>
                <Header />
                <div className='project-content-container'>
                    <Routes >

                        <Route>
                            <Route exact path='/role-management/*' element={<RoleManagement />}></Route>
                            <Route exact path='/team-management/*' element={<TeamManagement />}></Route>
                            <Route exact path='/contact-requests/*' element={<ContactRequests />}></Route>
                            <Route exact path='/demo-requests/*' element={<DemoRequests />}></Route>
                            <Route exact path='/customers/*' element={<Customers />}></Route>
                            <Route exact path='/view-customer/:customerId/*' element={<ViewCustomer />}></Route>

                            <Route exact path='/subscription-plans/*' element={<SubscriptionPlans />}></Route>
                            <Route exact path='/view-subscription-plan/:planId/*' element={<ViewSubscriptionPlan />}></Route>
                            <Route exact path='/edit-subscription-plan/:planId/*' element={<EditSubscriptionPlan props={{ menuType: 'edit' }} />}></Route>
                            <Route exact path='/add-subscription-plan/*' element={<EditSubscriptionPlan props={{ menuType: 'create' }} />}></Route>

                            <Route exact path='/settings/*' element={<Settings />}></Route>
                        </Route>
                    </Routes>
                </div>
            </div>
        </div>
    )
}

export default Admin;
