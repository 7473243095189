
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class DemoRequests {

    constructor() {

    }

    async getDemoRequestssHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/admin/demorequests/get-requests', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }



    async updateDemoRequestHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/admin/demorequests/update-request', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }


}

export default DemoRequests;