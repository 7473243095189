

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import CustomersHandler from '../../Handlers/Customers/Customers';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import SmarkTopCards from '../../components/SmarkTopCards'
import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const Customers = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const customersHandler = new CustomersHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")


    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--vendors', feature_key: 'vendors' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/app/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/admin/quick-menu`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [CustomerDetials, setCustomersDetials] = useState([])
    const [selectedAction, setSelectedAction] = useState('')

    const [ActionDropdown, setActionDropdown] = useState([
        {
            id: 'edit',
            label: 'View',
            icon: Icons.general.open_link,

        },
    ])


    const tabelHeader = [

        {
            id: 'sno',
            label: 'Cust.ID',
            format: (value) => `CUST-${value}`,

        },
        {
            id: 'name',
            label: 'Name',

        },
        {
            id: 'email',
            label: 'Email',

        },
        {
            id: 'phone',
            label: 'Phone',

        },
        {
            id: 'location',
            label: 'Location',
        },
        {
            id: 'builder_type',
            label: 'Builder Type',
            format: (value) => String(value).toLocaleUpperCase()
        },
        {
            id: 'approved',
            label: 'Status',
            format: (value) => {
                if (value == "0") return "Waiting for Approval"
                else if (value == "1") return "Approved"
                else if (value == "2") return "Rejected"
            },
        },
        {
            id: 'register_verified',
            label: 'Registered',
            format: (value) => {
                if (value == "0") return "No"
                else if (value == "1") return "Yes"
            },
        },
        {
            id: 'subscribed_after_trial',
            label: 'Plan Type',
            format: (value) => {
                if (value == "0") return "Trial"
                else if (value == "1") return "Subscribed"
            },
        },
        {
            id: 'plan_name',
            label: 'Plan',
        },
        {
            id: 'plan_amount',
            label: 'Plan Amount',
            format: (value) => value ? `₹${parseInt(value || 0)}` : 'N/A'
        },
        {
            id: 'subscription_status_label',
            label: 'Subscription',
        },
        {
            id: 'actions',
            label: 'Actions',
            minWidth: 50,
            align: 'right',
            format: (value) => value,
        },
    ];

    const getCustomerDetials = async () => {

        let response = await customersHandler.getCustomersHandler()

        if (response && response.success) {

            let customers = response.data || []

            customers = customers.map((customer) => {

                let subscription_detials = customer.subscription_detials || undefined

                if (subscription_detials) {
                    customer.plan_name = subscription_detials.plan_name
                    customer.plan_amount = subscription_detials.plan_amount

                    customer.subscribed_after_trial = subscription_detials.subscribed_after_trial
                    customer.subscription_trial_period_end_date = subscription_detials.trial_period_end_date
                    customer.subscription_trial_period_start_date = subscription_detials.trial_period_start_date
                    customer.subscription_duration = subscription_detials.duration
                    customer.subscription_duration_type = subscription_detials.duration_type

                    customer.subscription_amount = subscription_detials.subscription_amount
                    customer.subscription_date_subdcribed = subscription_detials.date_subdcribed
                    customer.subscription_date_unsubdcribed = subscription_detials.date_unsubdcribed

                    customer.subscription_payment_method = subscription_detials.payment_method
                    customer.subscription_payment_order_id = subscription_detials.payment_order_id

                    let subscription_status = -1
                    let subscription_expiry_duration_days = 0

                    if (
                        (
                            subscription_detials.subscribed_after_trial == '0'
                            && subscription_detials.trial_period_end_date
                            && new Date(parseFloat(subscription_detials.trial_period_end_date)).getTime() < new Date().getTime()
                        )
                        || (
                            subscription_detials.subscribed_after_trial == '1'
                            && subscription_detials.date_unsubdcribed
                            && new Date(parseFloat(subscription_detials.date_unsubdcribed)).getTime() < new Date().getTime()
                        )
                    ) {
                        subscription_status = 2
                    } else {

                        let expiry_days = subscription_detials.subscribed_after_trial == '0' ? Utils.getDaysBetweenDates(parseFloat(subscription_detials.trial_period_end_date || 0), new Date()) : Utils.getDaysBetweenDates(parseFloat(subscription_detials.date_unsubdcribed || 0), new Date())

                        subscription_expiry_duration_days = expiry_days
                        subscription_status = 1
                    }

                    customer.subscription_status_label = subscription_status == '2' ? 'Expired' : subscription_status == '1' ? subscription_expiry_duration_days < 15 ? `${subscription_expiry_duration_days}days` : 'Active' : 'Inactive'

                    customer.subscription_status = subscription_status
                    customer.expiry_soon = subscription_expiry_duration_days < 15
                    customer.subscription_expiry_duration_days = subscription_expiry_duration_days

                }

                return customer

            })

            setCustomersDetials(customers)

            HandleSmartCardSetup(customers)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in getting vendor detials`)
        }
    }


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const HandleViewCustomer = (vendor) => {
        if (!has_view_access) return
        navigate(`/admin/app/view-customer/${vendor.sno}`)

    }

    const HanelCellAction = (id, row) => {

        if (id == "sno") HandleViewCustomer(row)
        if (id == "actions") {
            setSelectedAction(row.id)
            HandleActionDisable(row.id)
        }
    }

    const HandleActionDisable = (id) => {

        let input = document.getElementById(`clients-tabel-action-btn-${id}`)
        let dropdown_items = document.getElementById(`clients-tabel-action-dropdown-${id}`)

        const Event = (e) => {
            let path = e.path || (e.composedPath && e.composedPath());

            if (Array.isArray(path) && !path.includes(dropdown_items) && !path.includes(input)) {
                setSelectedAction('')
                window.removeEventListener('click', Event)
            }
        }

        window.addEventListener('click', Event)
    }

    useEffect(() => {
        getCustomerDetials()
    }, [])

    const [SmarkCard_Items, setSmarkCard_Items] = useState([])

    const HandleSmartCardClick = (id) => { }

    const HandleSmartCardSetup = (items) => {

        items = items || []

        let total = items.length
        let total_subscribed_customers = items.filter(itm => itm.subscribed_after_trial == "1").length
        let total_trail_customers = items.filter(itm => itm.subscribed_after_trial == "0").length

        let smark_cards = [
            {
                id: 'lm-smart-top-cards-total',
                label: 'Total Customers',
                value: total || "0",
                icon: Icons.general.users_group,
                unit_name: '',
                style: {
                    color: '#0065ff',
                    bg_linear_colors: {
                        start: '#e5efff',
                        end: '#e5f9ff'
                    },
                    border_color: '#c6d4dc',
                },
            },
            {
                id: 'lm-smart-top-cards-total_subscribed_customers',
                label: 'Subscribed Customers',
                value: total_subscribed_customers || "0",
                icon: Icons.general.users_group,
                unit_name: '',
                style: {
                    color: '#33c87e',
                    bg_linear_colors: {
                        start: '#eaf9f2',
                        end: '#f2fef7'
                    },
                    border_color: '#ccd8d2',
                },
            },
            {
                id: 'lm-smart-top-cards-total_trail_customers',
                label: 'Free-Trial Customers',
                value: total_trail_customers || "0",
                icon: Icons.general.users_group,
                unit_name: '',
                style: {
                    color: '#fb993d',
                    bg_linear_colors: {
                        start: '#fff4ea',
                        end: '#fffdf4'
                    },
                    border_color: '#dcd6cd',
                },
            },
        ]

        setSmarkCard_Items(smark_cards)

    }

    return (
        <>
            <div className="project-clients-main">
                <div className="clients-panel-content">


                    {SmarkCard_Items.length ?
                        <SmarkTopCards props={{
                            id: 'labourManagement-smart-top-cards',
                            cards: SmarkCard_Items || [],
                            callback: HandleSmartCardClick
                        }} />
                        : ''}
                    <div className="smart-panel-content-main">
                        <div className="panel-content-header">
                            <div className="title">Customers</div>
                            <div className="buttons">
                            </div>
                        </div>
                        <div className="panel-content-sections panel-content-tabel-section">
                            <Paper style={{ width: '100%', height: '100%', boxShadow: 'none', overflow: 'hidden' }}>
                                <TableContainer style={{ height: '93%', boxShadow: 'none !important', }}>
                                    <Table stickyHeader>
                                        <TableHead
                                            className='tabel-header'
                                        >
                                            <TableRow>
                                                {tabelHeader.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                        className='tabel-header-items'
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className='tabel-body'>
                                            {CustomerDetials
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                            {tabelHeader.map((column) => {
                                                                const value = row[column.id];
                                                                return (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}
                                                                        onClick={(e) => {
                                                                            if (column.id != 'actions') HanelCellAction(column.id, row)
                                                                        }}
                                                                    >
                                                                        {
                                                                            column.id == 'sno' ?
                                                                                <div
                                                                                    className="tabel-name"
                                                                                >
                                                                                    {column.format ? column.format(value) : value}
                                                                                </div>

                                                                                : column.id == 'actions' && has_view_access ?

                                                                                    <div
                                                                                        className="tabel-action-main"
                                                                                    >
                                                                                        <div
                                                                                            className="action-icon clients-tabel-action-btn"
                                                                                            id={`clients-tabel-action-btn-${row.id}`}
                                                                                            dangerouslySetInnerHTML={{ __html: Icons.general.dotted_action }}
                                                                                            onClick={(e) => HanelCellAction(column.id, row)}
                                                                                        ></div>
                                                                                        <div
                                                                                            className={`action-dropdown clients-tabel-action-dropdown ${selectedAction == row.id ? 'action-dropdown-active' : ''}`}
                                                                                            id={`clients-tabel-action-dropdown${row.id}`}
                                                                                        >
                                                                                            {ActionDropdown.map(option => (

                                                                                                !has_view_access ? '' :
                                                                                                    <div
                                                                                                        className="action-dropdown-item"
                                                                                                        key={`proposals-action${option.id}`}
                                                                                                        onClick={(e) => {
                                                                                                            setSelectedAction('')
                                                                                                            if (option.id == 'edit') HandleViewCustomer(row)

                                                                                                        }}
                                                                                                    >
                                                                                                        <div
                                                                                                            className="icon"
                                                                                                            dangerouslySetInnerHTML={{ __html: option.icon }}
                                                                                                        ></div>
                                                                                                        <div className="label">{option.label}</div>
                                                                                                    </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    : column.format ? column.format(value) : value
                                                                        }
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    style={{ height: '7%', overflow: 'hidden' }}
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={CustomerDetials.length || 0}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Customers;