

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import RoleManagement from '../../Handlers/RoleManagement/RoleManagement';
import TeamManagementHandler from '../../Handlers/TeamManagement/TeamManagement';

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import SmarkTopCards from '../../components/SmarkTopCards'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const AddNewUserPopup = ({ props }) => {

    const { type, heading, close, callback, detials } = props

    const { empId } = useParams()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const roleManagement = new RoleManagement()
    const teamManagement = new TeamManagementHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--employees', feature_key: 'teamManagement' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/app/quick-menu`, { state: { from: location }, replace: true })
            }

            if (type == 'edit' && menu_permission._edit == '0') navigate(`${ReverseProxy['proxyUrl']}/admin/quick-menu`, { state: { from: location }, replace: true })
            if (type == 'create' && menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/admin/quick-menu`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [Name, setName] = useState('')
    const [Email, setEmail] = useState('')
    const [Phone, setPhone] = useState('')
    const [Role, setRole] = useState('')
    const [RoleName, setRoleName] = useState('')

    const [RoleOptions, setRoleOptions] = useState([])

    const HandleCancel = async (e) => {
        close(type)
    }
    const HandlePopupSubmit = async (e) => {

        let updateta = {
            name: Name,
            email: Email,
            phone: Phone,
            role_id: Role,
            role_name: RoleName,

        }

        let response = { success: false }

        if (type == 'edit') {

            updateta["id"] = empId
            response = await teamManagement.updateTeamUserHandler(updateta)

        }

        else response = await teamManagement.createTeamUserHandler(updateta)

        if (response.success) {
            callback()
            close(type)
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in updating profile`)
        }


    }
    const Popup_Header = () => {

        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <div className="label">{heading}</div>
                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => HandleCancel(e)}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };

    const Popup_Footer = () => {

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                    </div>
                    <div className='action-btns'>
                        <div className="action-cancel" onClick={(e) => HandleCancel(e)}>
                            Cancel
                        </div>
                        <div
                            className={`action-btn action-${type}`}
                            onClick={(e) => {
                                HandlePopupSubmit(e)
                            }}
                        >
                            <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.save }}></div>
                            <div className="label">Save</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const getRolesDetials = async () => {

        if (store.user && Array.isArray(store.user.role_management_access_basics) && store.user.role_management_access_basics.length) {

            setRoleOptions(store.user.role_management_access_basics)

        } else {


            let response = await roleManagement.getRoleBasicsHandler()

            if (response && response.success) {

                let { roles } = response.data

                if (!Array.isArray(roles)) return

                let role_detials = roles.map((role, idx) => {
                    return {
                        value: role.id,
                        label: role.name
                    }
                })

                setRoleOptions(role_detials)

                dispatch(updateState({
                    type: "SET_ROLE_BASICS",
                    payload: { role_management_access_basics: role_detials }
                }))

            } else {
                setWarningAlert(true)
                setWarningAlertType('error')
                setApiFailedMessage(`Error in getting role detials`)
            }
        }
    }


    useEffect(() => {

        if (type == 'edit' && empId) {

            const LoadUsers = async (empId) => {

                if (Object.keys(detials).length) {

                    let { id, name, email, phone, role_id, role_name, status, updated_at, updated_by } = detials

                    setName(name)
                    setEmail(email)
                    setPhone(phone)
                    setRole(role_id)
                    setRoleName(role_name)


                }
                else {

                    let response = await teamManagement.getTeamUsersHandler({
                        id: empId
                    })

                    if (response && response.success && Array.isArray(response.data) && response.data.length) {

                        let { id, name, email, phone, role_id, role_name, status, updated_at, updated_by } = response.data[0]

                        setName(name)
                        setEmail(email)
                        setPhone(phone)
                        setRole(role_id)
                        setRoleName(role_name)

                    }
                    else {
                        setWarningAlert(true)
                        setWarningAlertType('error')
                        setApiFailedMessage(`Error in getting user detials`)
                    }
                }
            }

            LoadUsers(empId)
        }

        getRolesDetials()
    }, [])

    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Team Management",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <div className="popup-container-main popup-container-center">
                <div className="popup-block-ui"></div>
                <div className="side-popup-container center-popup-container" >
                    <Popup_Header />
                    <div className="teamManagement-popup-content" style={{ minHeight: '250px' }}>
                        <div className="content-items">
                            <div className="content-item">
                                <TextInput
                                    props={{
                                        id: "teamManagement-name",
                                        value: Name,
                                        placeholder: '',
                                        setValue: (value) => setName(value),
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Name",
                                    }}
                                />
                            </div>
                            <div className="content-item">
                                <TextInput
                                    props={{
                                        id: "teamManagement-email",
                                        value: Email,
                                        placeholder: '',
                                        setValue: (value) => setEmail(value),
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Email",
                                    }}
                                />
                            </div>
                        </div>
                        <div className="content-items">
                            <div className="content-item">
                                <SelectInput
                                    props={{
                                        id: "teamManagement-role",
                                        value: Role,
                                        placeholder: '',
                                        options: RoleOptions || [],
                                        setValue: (value, label) => {
                                            setRole(value)
                                            setRoleName(label)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Role",
                                    }}
                                />
                            </div>
                            <div className="content-item">
                                <TextInput
                                    props={{
                                        id: "teamManagement-phone",
                                        value: Phone,
                                        placeholder: '',
                                        setValue: (value) => setPhone(value),
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Phone",
                                    }}
                                />
                            </div>

                        </div>
                    </div>
                    <Popup_Footer />
                </div>
            </div>
        </>
    )
}

const TeamManagement = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const teamManagement = new TeamManagementHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [UsersDetials_original, setUsersDetials_original] = useState([])
    const [UsersDetials, setUsersDetials] = useState([])
    const [selected_termsCondition, setSelected_termsCondition] = useState({})
    const [selectedAction, setSelectedAction] = useState('')

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--employees', feature_key: 'teamManagement' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/app/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/admin/quick-menu`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [ActionDropdown, setActionDropdown] = useState([
        {
            id: 'edit',
            label: 'Edit',
            icon: Icons.general.edit,

        },
        {
            id: 'delete',
            label: 'Delete',
            icon: Icons.general.delete,

        },
    ])


    const tabelHeader = [
        {
            id: 'name',
            label: 'Name',

        },
        {
            id: 'email',
            label: 'Email',

        },
        {
            id: 'phone',
            label: 'Phone',

        },
        {
            id: 'role_name',
            label: 'Role',

        },
        {
            id: 'status',
            label: 'Status',
            format: (value) => {
                if (value == "0") return "Inactive"
                if (value == "1") return "Active"
            },

        },
        {
            id: 'actions',
            label: 'Actions',
            minWidth: 50,
            align: 'right',
            format: (value) => value,
        },
    ];

    const getUsersDetials = async () => {

        let response = await teamManagement.getTeamUsersHandler()

        if (response && response.success) {

            setUsersDetials_original(response.data)
            setUsersDetials(response.data)

            HandleSmartCardSetup(response.data)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in getting users detials`)
        }
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const HandleAddUser = () => {
        if (!has_add_access) return
        navigate(`${window.location.pathname}/add-user`)
    }
    const HandleEditUser = (user) => {
        if (!has_edit_access) return
        setSelected_termsCondition(user)

        navigate(`${window.location.pathname}/edit/${user.id}`)
    }
    const HandleDeleteUser = async (user) => {
        if (!has_edit_access || !has_delete_access) return
        let response = await teamManagement.deleteTeamUserHandler({
            id: user.id
        })

        if (response && response.success) {

            getUsersDetials()

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in deleting user detials`)
        }

    }
    const CloseAddUserPopup = (type) => {

        let back_path = String(window.location.pathname).split('/')
        back_path.pop()
        if (type == 'edit') back_path.pop()

        back_path = back_path.join('/')

        navigate(`${back_path}`)
    }

    const HanelCellAction = (id, row) => {

        console.log(selectedAction, 'selectedAction');
        if (id == "name") HandleEditUser(row)
        if (id == "actions") {
            setSelectedAction(row.id)
            HandleActionDisable(row.id)
        }
    }

    const HandleActionDisable = (id) => {

        let input = document.getElementById(`teamManagement-tabel-action-btn-${id}`)
        let dropdown_items = document.getElementById(`teamManagement-tabel-action-dropdown-${id}`)

        const Event = (e) => {
            let path = e.path || (e.composedPath && e.composedPath());

            if (Array.isArray(path) && !path.includes(dropdown_items) && !path.includes(input)) {
                setSelectedAction('')
                window.removeEventListener('click', Event)
            }
        }

        window.addEventListener('click', Event)
    }

    useEffect(() => {
        getUsersDetials()
    }, [])

    const [SmarkCard_Items, setSmarkCard_Items] = useState([])

    const HandleSmartCardClick = (id) => { }

    const HandleSmartCardSetup = (items) => {

        items = items || []

        let total = items.length
        let total_active = items.filter(itm => itm.status == "1").length
        let total_inactive = items.filter(itm => itm.status == "0").length

        let smark_cards = [
            {
                id: 'lm-smart-top-cards-total',
                label: 'Total Employees',
                value: total || "0",
                icon: Icons.general.users,
                unit_name: '',
                style: {
                    color: '#0065ff',
                    bg_linear_colors: {
                        start: '#e5efff',
                        end: '#e5f9ff'
                    },
                    border_color: '#c6d4dc',
                },
            },
            {
                id: 'lm-smart-top-cards-total_active',
                label: 'Active Employees',
                value: total_active || "0",
                icon: Icons.general.users,
                unit_name: '',
                style: {
                    color: '#33c87e',
                    bg_linear_colors: {
                        start: '#eaf9f2',
                        end: '#f2fef7'
                    },
                    border_color: '#ccd8d2',
                },
            },
            {
                id: 'lm-smart-top-cards-outstanding_balance',
                label: 'Inactive Employees',
                value: total_inactive || "0",
                icon: Icons.general.users,
                unit_name: '',
                style: {
                    color: '#ff5b5b',
                    bg_linear_colors: {
                        start: '#ffeded',
                        end: '#fff9f9'
                    },
                    border_color: '#dcd1d1',
                },
            },
        ]

        setSmarkCard_Items(smark_cards)

    }

    return (
        <>
            <Routes >
                <Route exact path='/add-user' element={
                    <AddNewUserPopup props={{
                        type: "create",
                        heading: "Add Employee",
                        callback: getUsersDetials,
                        close: CloseAddUserPopup
                    }} />}></Route>
                <Route exact path='/edit/:empId' element={
                    <AddNewUserPopup props={{
                        type: "edit",
                        heading: "Edit Employee",
                        callback: getUsersDetials,
                        close: CloseAddUserPopup,
                        detials: selected_termsCondition
                    }} />}></Route>
            </Routes>

            <div className="project-teamManagement-main">
                <div className="teamManagement-panel-content">

                    {SmarkCard_Items.length ?
                        <SmarkTopCards props={{
                            id: 'labourManagement-smart-top-cards',
                            cards: SmarkCard_Items || [],
                            callback: HandleSmartCardClick
                        }} />
                        : ''}
                    <div className="smart-panel-content-main">
                        <div className="panel-content-header">
                            <div className="title">Employees</div>
                            <div className="buttons">
                                {has_add_access ?
                                    <div
                                        className="button add-button"
                                        onClick={(e) => HandleAddUser()}
                                    >
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_active }}></div>
                                        <div className="label">Add New</div>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                        <div className="panel-content-sections panel-content-tabel-section">
                            <Paper style={{ width: '100%', height: '100%', boxShadow: 'none', overflow: 'hidden' }}>
                                <TableContainer style={{ height: '93%', boxShadow: 'none !important', }}>
                                    <Table stickyHeader>
                                        <TableHead
                                            className='tabel-header'
                                        >
                                            <TableRow>
                                                {tabelHeader.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                        className='tabel-header-items'
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className='tabel-body'>
                                            {UsersDetials
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                            {tabelHeader.map((column) => {
                                                                const value = row[column.id];
                                                                return (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}
                                                                        onClick={(e) => {
                                                                            if (column.id != 'actions') HanelCellAction(column.id, row)
                                                                        }}
                                                                    >
                                                                        {
                                                                            column.id == 'name' ?
                                                                                <div
                                                                                    className="tabel-name"
                                                                                >
                                                                                    {column.format ? column.format(value) : value}
                                                                                </div>

                                                                                : column.id == 'actions' && has_edit_access ?

                                                                                    <div
                                                                                        className="tabel-action-main"
                                                                                    >
                                                                                        <div
                                                                                            className="action-icon teamManagement-tabel-action-btn"
                                                                                            id={`teamManagement-tabel-action-btn-${row.id}`}
                                                                                            dangerouslySetInnerHTML={{ __html: Icons.general.dotted_action }}
                                                                                            onClick={(e) => HanelCellAction(column.id, row)}
                                                                                        ></div>
                                                                                        <div
                                                                                            className={`action-dropdown teamManagement-tabel-action-dropdown ${selectedAction == row.id ? 'action-dropdown-active' : ''}`}
                                                                                            id={`teamManagement-tabel-action-dropdown${row.id}`}
                                                                                        >
                                                                                            {ActionDropdown.map(option => (


                                                                                                !has_edit_access ? '' :
                                                                                                    option.id == 'delete' && !has_delete_access ? '' :

                                                                                                        <div
                                                                                                            className="action-dropdown-item"
                                                                                                            key={`proposals-action${option.id}`}
                                                                                                            onClick={(e) => {
                                                                                                                setSelectedAction('')
                                                                                                                if (option.id == 'edit') HandleEditUser(row)
                                                                                                                else if (option.id == 'delete') HandleDeleteUser(row)

                                                                                                            }}
                                                                                                        >
                                                                                                            <div
                                                                                                                className="icon"
                                                                                                                dangerouslySetInnerHTML={{ __html: option.icon }}
                                                                                                            ></div>
                                                                                                            <div className="label">{option.label}</div>
                                                                                                        </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    : column.format ? column.format(value) : value
                                                                        }
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    style={{ height: '7%', overflow: 'hidden' }}
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={UsersDetials.length || 0}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default TeamManagement;