import React, { useEffect, useState } from 'react'

/* Redux Setup*/
import { Link, useNavigate, Route, Routes, BrowserRouter, Switch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import StoreHandler from './redux/StoreHandler'
import UserAction from './redux/action/userAction'

import './assets/css/index.css'

import ReverseProxy from './config/reverseProxy'

import Preloader from './components/Preloader'

import Login from './PagesAdmin/login/login'
import Signup from './PagesAdmin/login/signup'
import Forgetpassword from './PagesAdmin/login/forgetpassword'
import ResetPassword from './PagesAdmin/login/resetpassword'
import VerifyResetPassword from './PagesAdmin/login/verifyresetpassword'

import AuthTokenValidation from './Handlers/Authendication/AuthTokenValidation'

import Icons from './assets/Icons'
import Images from './assets/Images'

import QuickMenus from './PagesAdmin/quickMenus'
import Admin from './PagesAdmin/Admin'

const App = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()
    const authToken = localStorage.getItem('authToken')
    const [preloaderStatus, setPreloaderStatus] = useState(true)

    const storeHandler = new StoreHandler({
        dataStack: ['user'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        let resolved = await storeHandler.init()
    }

    useEffect(() => {

        const user_detials = store.user.user
        const currentURL = window.location.pathname;
        let currentPath = String(currentURL).split('/')
        currentPath = currentPath[currentPath.length - 1]

        if (user_detials && Object.keys(user_detials).length > 0) {
            if (currentPath == 'admin') navigate(`${ReverseProxy['proxyUrl']}/app/quick-menu`)
        }

    }, [store])



    useEffect(() => {
        LoadStoreData()
    }, [])


    return (

        <div className='project-container-main'>

            {preloaderStatus ? <Preloader props={{ delay: 100, setPreloaderStatus }} /> : null}

            <Routes >
                <Route exact path='/signup' element={<Signup />}></Route>
                <Route exact path='/signin' element={<Login />}></Route>
                <Route exact path='/forget-password' element={<Forgetpassword />}></Route>
                <Route exact path='/verify-reset-password' element={<VerifyResetPassword />}></Route>
                <Route exact path='/reset-password' element={<ResetPassword />}></Route>
            </Routes>

            <AuthTokenValidation />

            {store.user.isAuthenticated ?
                <div className='project-main-container'>
                    <Routes >
                        <Route exact path='/quick-menu/*' element={<QuickMenus />}></Route>
                        <Route exact path='/app/*' element={<Admin />}></Route>
                    </Routes>
                </div>
                : ''}

        </div>
    )
}

export default App;
