

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'
import StoreHandler from '../../redux/StoreHandler'

import ReverseProxy from '../../config/reverseProxy'
import AdminUser from '../../Handlers/AdminUser/AdminUser';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'


import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'


const Profile = () => {

    const adminHandler = new AdminUser()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [profileImg_url, setProfileImg_url] = useState('')

    const [profileImg, setProfileImg] = useState({})
    const [userDetials, setUserDetials] = useState({})

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['user'],
        dispatch: dispatch,
        store: store,
        updateState: updateState,
    })
    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const HandleformSubmit = async (e) => {
        e.preventDefault()

        const formData = new FormData();

        formData.append('name', name)
        formData.append('phone', phone)
        formData.append('profile_img', profileImg)

        setIsLoading(true)

        let response = await adminHandler.updateProfileHandler(formData)

        if (response.success) {
            LoadStoreData()
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in updating profile`)
        }
    }

    const handleImageChange = (event) => {
        const file = event.target.files[0];

        if (!file) return

        setProfileImg(file);

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setProfileImg_url(reader.result)
        };


    };
    const uploadImage = (event) => {

        let input = document.getElementById('userprofile-profile-image')
        input.click()
    };

    const isFile = (variable) => {
        return variable !== null && typeof variable === 'object' && variable.constructor === File;
    }

    useEffect(() => {

        let loadImg = () => {

            if (!isFile(profileImg)) return

            let input = document.getElementById('userprofile-profile-image')
            const fileList = new DataTransfer();
            fileList.items.add(profileImg);

            input.files = fileList.files;
        }

        loadImg()

    }, [profileImg])


    useEffect(() => {

        if (store.user.user) setUserDetials(store.user.user)

    }, [store])

    useEffect(() => {

        if (userDetials) {

            setName(userDetials.name)
            setPhone(userDetials.phone)
            setEmail(userDetials.email)

            if (userDetials.profile_img) {
                setProfileImg(Utils.dataURLtoFile(`data:image/png;base64,${userDetials.profile_img}`, userDetials.name))
                setProfileImg_url(`data:image/png;base64,${userDetials.profile_img}`)
            }
        }



    }, [userDetials])

    return (

        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Profile update",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <form className="settings-panel-content" onSubmit={HandleformSubmit}>
                <div className="panel-content-main">

                    <div className="panel-content-header">
                        <div className="title">My Profile</div>
                    </div>
                    <div className="panel-content-sections">


                        <div className="profile-image-main">
                            <div className="profile-image">
                                <img src={profileImg_url || Images.User} />
                            </div>
                            <div className="profile-image-btn">

                                <span className='label'>Profile photo</span>
                                <span className='sm-label'>PNG or JPG (max 1MB)</span>
                                <input className='upload-input' accept="image/jpg, image/jpeg, image/png" onChange={(e) => handleImageChange(e)} type="file" id='userprofile-profile-image' />
                                <span className='upload-button' onClick={uploadImage}>Choose Image</span>
                            </div>
                        </div>

                        <div className="panel-content-section">
                            <div className="content-items">
                                <div className="content-item">
                                    <TextInput
                                        props={{
                                            id: "createclient-name",
                                            value: name,
                                            placeholder: '',
                                            setValue: (value) => setName(value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Name",
                                        }}
                                    />
                                </div>
                                <div className="content-item">
                                    <TextInput
                                        props={{
                                            id: "createclient-email",
                                            value: email,
                                            readOnly: true,
                                            placeholder: '',
                                            setValue: (value) => setEmail(value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Email",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="content-items">
                                <div className="content-item">
                                    <TextInput
                                        props={{
                                            id: "createclient-phone",
                                            value: phone,
                                            placeholder: '',
                                            setValue: (value) => setPhone(value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Phone",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel-content-footer">
                    <div className="content-footer-items">
                        <div className="footer-button">
                            <div className="label">Cancel</div>
                        </div>
                        <button type='submit' className="footer-button footer-button-active">
                            <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.save }}></div>
                            <div className="label">Save Changes</div>
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default Profile;
