

import React, { useEffect, useState, useRef, Fragment } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import CustomersHandler from '../../Handlers/Customers/Customers';
import SubscriptionPlansHandler from '../../Handlers/SubscriptionPlans/SubscriptionPlans';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'



const EditSubscriptionPlan = ({ props }) => {

    const { planId } = useParams();

    const { menuType } = props

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['user'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--vendors' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    const [ApprovalStatusAction, setApprovalStatusAction] = useState('')
    const [ApprovalStatusAlert, setApprovalStatusAlert] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/admin/quick-menu`, { state: { from: location }, replace: true })
            }
            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const subscriptionPlansHandler = new SubscriptionPlansHandler()

    const HandleCancel = (e) => {

        navigate('/admin/app/subscription-plans')
    }

    const ConstantPlanModules = [
        {
            "id": "overall_dashboard",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": -1,
            "_add": -1,
            "has_edit": -1,
            "_edit": -1,
            "has_delete": -1,
            "_delete": -1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "global_menus--overall_dashboard",
            "feature_name": "Dashboard",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "work_categories",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": 1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "master_features--work_categories",
            "feature_name": "Work Categories",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "schedule_rates",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": 1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "master_features--schedule_rates",
            "feature_name": "Schedule Rates",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "work_rates",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": 1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "master_features--work_rates",
            "feature_name": "Work Rates",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "work_templates",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": 1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "master_features--work_templates",
            "feature_name": "Work Templates",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "all_labours",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": 1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "labour_managment--all_labours",
            "feature_name": "Labour profiles",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "clients",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": 1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "global_menus--clients",
            "feature_name": "Clients",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "vendors",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": 1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "global_menus--vendors",
            "feature_name": "Sub/Vendors",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "employees",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": 1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "global_menus--employees",
            "feature_name": "Employees",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "role_managment",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": 1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "global_menus--role_managment",
            "feature_name": "User Roles & Permissions",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "company_profile",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": -1,
            "_add": -1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": -1,
            "_delete": -1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "settings--company_profile",
            "feature_name": "Company Profile",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "system_configuration",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": -1,
            "_add": -1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": -1,
            "_delete": -1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "settings--system_configuration",
            "feature_name": "System Configuration",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "notification_settings",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": -1,
            "_add": -1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": -1,
            "_delete": -1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "settings--notification_settings",
            "feature_name": "Notifications",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "licenece_insurence",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": -1,
            "_add": -1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": -1,
            "_delete": -1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "settings--licenece_insurence",
            "feature_name": "Licenece & Insurance",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "terms_conditions",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": 1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "settings--terms_conditions",
            "feature_name": "Terms & Conditions",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "payment_schedule",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": 1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "settings--payment_schedule",
            "feature_name": "Payment Schedule",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "field_settings",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": 1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "settings--field_settings",
            "feature_name": "Field Settings",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "finance_settings",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": 1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "settings--finance_settings",
            "feature_name": "Finance Settings",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "projects",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": 1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "project_management--projects",
            "feature_name": "Project Profile",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "project_dashboard",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": -1,
            "_add": -1,
            "has_edit": -1,
            "_edit": -1,
            "has_delete": -1,
            "_delete": -1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "project_management--project_dashboard",
            "feature_name": "Project Dashboard",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "project_phases",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": -1,
            "has_edit": 1,
            "_edit": -1,
            "has_delete": 1,
            "_delete": -1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "project_management--project_phases",
            "feature_name": "Project Phases",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "project_estimate",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": 1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "pre_construction--project_estimate",
            "feature_name": "Estimate",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "project_proposal",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": 1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "pre_construction--project_proposal",
            "feature_name": "Proposals",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "boq_summary",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": -1,
            "_add": -1,
            "has_edit": -1,
            "_edit": -1,
            "has_delete": -1,
            "_delete": -1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "pre_construction--boq_summary",
            "feature_name": "BOQ Summary",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "purchase_orders",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": 1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "purchase--purchase_orders",
            "feature_name": "Purchase orders",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "grns",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": 1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "purchase--grns",
            "feature_name": "GRNs",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "bills",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": 1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "purchase--bills",
            "feature_name": "Bills",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "stock_items",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": -1,
            "_add": -1,
            "has_edit": -1,
            "_edit": -1,
            "has_delete": -1,
            "_delete": -1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "inventory--stock_items",
            "feature_name": "Stock Items",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "indents",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": 1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "inventory--indents",
            "feature_name": "Indenets",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "material_issues",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": 1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "inventory--material_issues",
            "feature_name": "Material issues",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "material_deliveries",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": -1,
            "_add": -1,
            "has_edit": 1,
            "_edit": -1,
            "has_delete": -1,
            "_delete": -1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "inventory--material_deliveries",
            "feature_name": "Deliveries",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "checklists",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": -1,
            "has_delete": -1,
            "_delete": -1,
            "has_approve": -1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "safety--checklists",
            "feature_name": "Checklists",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "checklists_templates",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": -1,
            "has_delete": -1,
            "_delete": -1,
            "has_approve": -1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "safety--checklists_templates",
            "feature_name": "Checklists Templates",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "labour_accounts",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": -1,
            "has_delete": -1,
            "_delete": -1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "labour_managment--labour_accounts",
            "feature_name": "Labour Accounts",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "labour_attendance",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": -1,
            "_delete": -1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "labour_managment--labour_attendance",
            "feature_name": "Attendance",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "labour_payroll",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": -1,
            "_add": -1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": -1,
            "_delete": -1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "labour_managment--labour_payroll",
            "feature_name": "Payroll",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "tasks",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": 1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "project_management--tasks",
            "feature_name": "Tasks",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "tasks_bulk_upload",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": -1,
            "_add": 1,
            "has_edit": -1,
            "_edit": 1,
            "has_delete": -1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "project_management--tasks_bulk_upload",
            "feature_name": "Task Bulk Upload",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "issues",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": 1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "project_management--issues",
            "feature_name": "Issues",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "finance_dashoard",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": -1,
            "_add": -1,
            "has_edit": -1,
            "_edit": -1,
            "has_delete": -1,
            "_delete": -1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "finance--finance_dashoard",
            "feature_name": "Finance Dashboard",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "finance_summary",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": -1,
            "_add": -1,
            "has_edit": -1,
            "_edit": -1,
            "has_delete": -1,
            "_delete": -1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "finance--finance_summary",
            "feature_name": "Summary",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "finance_budget",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": -1,
            "_add": -1,
            "has_edit": -1,
            "_edit": -1,
            "has_delete": -1,
            "_delete": -1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "finance--finance_budget",
            "feature_name": "Budget",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "changes_orders",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": 1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "finance--changes_orders",
            "feature_name": "Change Orders",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "client_payments",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": 1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "finance--client_payments",
            "feature_name": "Client Payments",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "drive",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": 1,
            "_add": 1,
            "has_edit": 1,
            "_edit": 1,
            "has_delete": 1,
            "_delete": 1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "project_management--drive",
            "feature_name": "Drive",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        },
        {
            "id": "project_reports",
            "has_feature": 0,
            "has_view": 1,
            "_view": 1,
            "has_add": -1,
            "_add": -1,
            "has_edit": -1,
            "_edit": -1,
            "has_delete": -1,
            "_delete": -1,
            "has_approve": 1,
            "_approve": 1,
            "_access": 1,
            "feature_key": "project_management--project_reports",
            "feature_name": "Reports",
            "has_view_setting": false,
            "view_settings_options": [],
            "selected_view_settings_value": null
        }
    ]

    const [PlanId, setPlanId] = useState('')
    const [PlanName, setPlanName] = useState('')
    const [PlanType, setPlanType] = useState('0')
    const [PlanCategory, setPlanCategory] = useState('0')
    const [PlanAmount, setPlanAmount] = useState('0')
    const [PlanUsers, setPlanUsers] = useState('10')
    const [PlanOffer, setPlanOffer] = useState('0')
    const [PlanStatus, setPlanStatus] = useState('0')

    const [PlanModules, setPlanModules] = useState([])

    const [PlanLastUpdated, setPlanLastUpdated] = useState('Last Update : 04:59 PM')

    const [PlanTypeOptions, setPlanTypeOptions] = useState([
        {
            value: '0',
            label: 'Free'
        },
        {
            value: '1',
            label: 'Paid',
        },
        {
            value: '2',
            label: 'Customized',
        }
    ])

    const [PlanCategoryOptions, setPlanCategoryOptions] = useState([
        {
            value: '0',
            label: 'Builder Plan'
        },
        {
            value: '1',
            label: 'Interior Designer',
        }
    ])

    const [PlanStatusOptions, setPlanStatusOptions] = useState([
        {
            value: '0',
            label: 'Inactive'
        },
        {
            value: '1',
            label: 'Active',
        }
    ])


    const LoadPlan = async () => {

        let filters = {
            id: planId
        }

        let response = await subscriptionPlansHandler.getPlansHandler(filters)

        if (response && response.success && Array.isArray(response.data) && response.data.length) {

            let plan = response.data[0]

            if (!plan) return navigate('/admin/app/subscription-plans')

            let lastupdated = `Last Updated : ${Utils._getLocalFullDate(plan.updated_at || plan.created_at)}`

            setPlanId(plan.id)
            setPlanName(plan.plan_name)
            setPlanType(plan.type)
            setPlanCategory(plan.plan_category)
            setPlanAmount(plan.current_price)
            setPlanUsers(plan.team_users_count)
            setPlanOffer(plan.offer_percent)
            setPlanStatus(plan.is_active)
            setPlanModules(plan.modules || [])
            setPlanLastUpdated(lastupdated)

            if (Array.isArray(plan.modules) && plan.modules.length) {

                let modules = ConstantPlanModules.map((module, index) => {

                    let is_module_available = plan.modules.find((m) => m.feature_key == module.feature_key)

                    if (is_module_available) module['has_feature'] = 1

                    return module
                })

                setPlanModules(modules)
            }

            console.log(plan, 'customer');



        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in getting plan detials`)

        }
    }


    const HandlePlanFeatureSelected = (feature, value, index) => {

        console.log(index, 'indexxx');

        let modules = [...PlanModules]

        let module = modules[index]

        module['has_feature'] = value

        setPlanModules(modules)
    }

    const HandleSubmit = async (e) => {

        let update_data = {
            plan_name: PlanName,
            type: PlanType,
            company_id: 'all',
            plan_category: PlanCategory,
            price: PlanAmount,
            team_users_count: PlanUsers,
            offer_percent: PlanOffer,
            is_active: PlanStatus,
            modules: PlanModules.filter((module) => module.has_feature == 1)
        }

        let response = undefined
        if (menuType == 'edit') {
            update_data['id'] = planId
            response = await subscriptionPlansHandler.updatePlanHandler(update_data)
        }
        else {
            response = await subscriptionPlansHandler.createPlanHandler(update_data)
        }



        if (response && response.success) {

            setWarningAlert(true)
            setWarningAlertType('success')
            setApiFailedMessage(`Plan detials ${menuType == 'edit' ? 'updated' : 'created'} successfully`)

            navigate('/admin/app/subscription-plans')

        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in ${menuType == 'edit' ? 'updating' : 'creating'} plan detials`)
        }
    }

    useEffect(() => {

        if (menuType == 'edit') LoadPlan()
        else {
            setPlanModules([...ConstantPlanModules])
        }


    }, [])



    return (
        <>



            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Vendors",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}





            <div className="customer-view-main">

                <div className="customer-content-main">

                    <div className="customer-section-main customer-header-main">

                        <div className=" header-content">
                            <div className="header-left">
                                <div className="header-back-btn"
                                    onClick={(e) => HandleCancel(e)}
                                    dangerouslySetInnerHTML={{ __html: Icons.general.back_arrow }}
                                ></div>
                                <div className="header-detials">
                                    <div className="header-name">{PlanName}</div>

                                    <div className="header-id">ID: {planId}</div>
                                    <div className="header-lastupdated">{PlanLastUpdated}</div>
                                </div>
                            </div>
                            <div className="header-right">
                                <div
                                    className="header-action-btn header-quick-btns header-edit-btn"
                                    onClick={(e) => HandleCancel(e)}
                                >
                                    <div className="label">Cancel</div>
                                </div>

                                <div
                                    className="header-action-btn header-quick-btns header-edit-btn"
                                    onClick={(e) => HandleSubmit(e)}
                                >
                                    <div className="label">Save</div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="customer-section-main customer-detials-main">

                        <div className="section-content">

                            <div className="section-header">
                                <div className="title">Plan Detials</div>
                            </div>

                            <div className="detials-content-main">

                                <div className="detials-content-section">


                                    <div className="detials-content-row">
                                        <div className="label">Plan Name</div>
                                        <div className="value">

                                            <TextInput
                                                props={{
                                                    id: "subscriptionplan-name",
                                                    value: PlanName,
                                                    placeholder: '',
                                                    setValue: (value) => {
                                                        setPlanName(value)
                                                    },
                                                    isIcon: false,
                                                    isLabel: false,
                                                    isRequired: true,
                                                    label: "PlanName",
                                                }}
                                            />

                                        </div>
                                    </div>

                                    <div className="detials-content-row">
                                        <div className="label">Plan Type</div>
                                        <div className="value">

                                            <SelectInput
                                                props={{
                                                    id: "subscriptionplan-plantype",
                                                    value: PlanType,
                                                    placeholder: '',
                                                    options: PlanTypeOptions || [],
                                                    setValue: (value, label) => {
                                                        setPlanType(value)
                                                    },
                                                    isIcon: false,
                                                    isLabel: false,
                                                    isRequired: true,
                                                    label: "PlanType",
                                                }}
                                            />

                                        </div>
                                    </div>
                                    <div className="detials-content-row">
                                        <div className="label">Plan Category</div>
                                        <div className="value">

                                            <SelectInput
                                                props={{
                                                    id: "subscriptionplan-plancategory",
                                                    value: PlanCategory,
                                                    placeholder: '',
                                                    options: PlanCategoryOptions || [],
                                                    setValue: (value, label) => {
                                                        setPlanCategory(value)
                                                    },
                                                    isIcon: false,
                                                    isLabel: false,
                                                    isRequired: true,
                                                    label: "PlanCategory",
                                                }}
                                            />

                                        </div>
                                    </div>
                                    <div className="detials-content-row">
                                        <div className="label">Plan Amount (₹)</div>
                                        <div className="value">
                                            <TextInput
                                                props={{
                                                    id: "subscriptionplan-planamount",
                                                    value: PlanAmount,
                                                    type: 'number',
                                                    placeholder: '',
                                                    setValue: (value) => {
                                                        setPlanAmount(value)
                                                    },
                                                    isIcon: false,
                                                    isLabel: false,
                                                    isRequired: true,
                                                    label: "PlanAmount",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="detials-content-row">
                                        <div className="label">Plan Users</div>
                                        <div className="value">
                                            <TextInput
                                                props={{
                                                    id: "subscriptionplan-planusers",
                                                    value: PlanUsers,
                                                    type: 'number',
                                                    placeholder: '',
                                                    setValue: (value) => {
                                                        setPlanUsers(value)
                                                    },
                                                    isIcon: false,
                                                    isLabel: false,
                                                    isRequired: true,
                                                    label: "PlanUsers",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="detials-content-section">


                                    <div className="detials-content-row">
                                        <div className="label">Plan Offer</div>
                                        <div className="value">
                                            <TextInput
                                                props={{
                                                    id: "subscriptionplan-planoffer",
                                                    value: PlanOffer,
                                                    type: 'number',
                                                    placeholder: '',
                                                    setValue: (value) => {
                                                        setPlanOffer(value)
                                                    },
                                                    isIcon: false,
                                                    isLabel: false,
                                                    isRequired: true,
                                                    label: "PlanOffer",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="detials-content-row">

                                        <div className="label">Plan Status</div>
                                        <div className="value">

                                            <SelectInput
                                                props={{
                                                    id: "subscriptionplan-planstatus",
                                                    value: PlanStatus,
                                                    placeholder: '',
                                                    options: PlanStatusOptions || [],
                                                    setValue: (value, label) => {
                                                        setPlanStatus(value)
                                                    },
                                                    isIcon: false,
                                                    isLabel: false,
                                                    isRequired: true,
                                                    label: "PlanStatus",
                                                }}
                                            />

                                        </div>
                                    </div>
                                    <div className="detials-content-row">

                                        <div className="label">Plan Modules</div>
                                        <div className="value">{PlanModules.filter((module) => module.has_feature == 1).length || 0}</div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="customer-section-main customer-detials-main">

                        <div className="section-content">

                            <div className="section-header">
                                <div className="title">Plan Features</div>

                            </div>

                            <div className="detials-content-main">

                                <div className="detials-content-section">
                                    {
                                        PlanModules.slice(0, PlanModules.length / 2).map((module, index) => (

                                            <div
                                                className="detials-content-row detials-feature-row"
                                                key={`module-${module.feature_key}`}
                                            >

                                                <div className="input">

                                                    <RadioInput props={{
                                                        value: module.has_feature ? true : false,
                                                        isIcon: false,
                                                        icon: "",
                                                        inputType: "checkbox",
                                                        name: "subscriptionplan-modules-radio",
                                                        setValue: (value) => HandlePlanFeatureSelected(module, value, index)
                                                    }} />

                                                </div>
                                                <div className="label" style={{ width: '400px' }}>{index + 1}.&nbsp;{module.feature_name}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="detials-content-section">
                                    {
                                        // PlanModules.slice((PlanModules.length - 1) / 2, PlanModules.length).map((module, index) => (
                                        PlanModules.slice(PlanModules.length / 2, PlanModules.length).map((module, index) => (

                                            <div
                                                className="detials-content-row detials-feature-row"
                                                key={`module-${module.feature_key}`}
                                            >

                                                <div className="input">

                                                    <RadioInput props={{
                                                        id: `module-${module.feature_key}`,
                                                        value: module.has_feature ? true : false,
                                                        isIcon: false,
                                                        icon: "",
                                                        inputType: "checkbox",
                                                        name: "subscriptionplan-modules-radio",
                                                        setValue: (value) => HandlePlanFeatureSelected(module, value, ((PlanModules.length / 2) + index))
                                                    }} />

                                                </div>
                                                <div className="label" style={{ width: '400px' }}>{((PlanModules.length / 2) + index) + 1}.&nbsp;{module.feature_name}</div>
                                            </div>
                                        ))
                                    }
                                </div>

                            </div>


                        </div>
                    </div>




                </div>
            </div >
        </>
    )

}

export default EditSubscriptionPlan;