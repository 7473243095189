

import React, { useEffect, useState, useRef, Fragment } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import CustomersHandler from '../../Handlers/Customers/Customers';
import SubscriptionPlansHandler from '../../Handlers/SubscriptionPlans/SubscriptionPlans';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const ActivateSubscriptionPopup = ({ props }) => {

    const { heading, close, callback, customerId } = props

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const customersHandler = new CustomersHandler()
    const subscriptionPlansHandler = new SubscriptionPlansHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--employees', feature_key: 'teamManagement' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/app/quick-menu`, { state: { from: location }, replace: true })
            }

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [PlanId, setPlanId] = useState('')
    const [PlanName, setPlanName] = useState('')
    const [PlanAmount, setPlanAmount] = useState('0')
    const [PlanUsers, setPlanUsers] = useState('10')
    const [DurationType, setDurationType] = useState('')
    const [Duration, setDuration] = useState('')
    const [SubscriptionAmount, setSubscriptionAmount] = useState('N/A')


    const [PlanDetials, setPlanDetials] = useState([])
    const [PlanOptions, setPlanOptions] = useState([])
    const [DurationTypeOptions, setDurationTypeOptions] = useState([
        {
            value: '1',
            label: 'Months'
        },
        {
            value: '2',
            label: 'Years'
        }
    ])

    const HandleCancel = async (e) => {
        close()
    }
    const HandlePopupSubmit = async (e) => {

        let update_data = {
            customer_id: customerId,
            plan_id: PlanId,
            plan_name: PlanName,
            team_users_count: PlanUsers,
            duration: Duration,
            duration_type: DurationType,
        }

        let response = await customersHandler.activateSubscriptionHandler(update_data)

        if (response && response.success) {

            setWarningAlert(true)
            setWarningAlertType('success')
            setApiFailedMessage(`Subscription Activated Successfully`)

            close()
            callback()
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Activating Subscription`)
        }


    }
    const Popup_Header = () => {

        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <div className="label">{heading}</div>
                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => HandleCancel(e)}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };

    const Popup_Footer = () => {

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                    </div>
                    <div className='action-btns'>
                        <div className="action-cancel" onClick={(e) => HandleCancel(e)}>
                            Cancel
                        </div>
                        <div
                            className={`action-btn action-success`}
                            onClick={(e) => {
                                HandlePopupSubmit(e)
                            }}
                        >
                            <div className="label">Activate</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const HandlePlanSection = (value, label) => {
        setPlanId(value)
        setPlanName(label)

        let plan = PlanDetials.find((plan) => plan.id == value)

        if (plan) {
            setPlanAmount(plan.current_price)
            setPlanUsers(plan.team_users_count)
            setSubscriptionAmount(plan.current_price)
        }
    }

    const HandleDurationChange = (value) => {
        setDuration(value)

        let subscription_months = DurationType == '1' ? parseInt(value || 0) : DurationType == '2' ? parseInt(value || 0) * 12 : 0
        let subscription_amount = (parseFloat(PlanAmount) || 0) * subscription_months
        setSubscriptionAmount(parseFloat(subscription_amount).toFixed(2) || 0)

    }
    const HandleDurationTypeChange = (value) => {
        setDurationType(value)

        let subscription_months = value == '1' ? parseInt(Duration || 0) : value == '2' ? parseInt(Duration || 0) * 12 : 0
        let subscription_amount = (parseFloat(PlanAmount) || 0) * subscription_months
        setSubscriptionAmount(parseFloat(subscription_amount).toFixed(2) || 0)

    }


    const getSubscriptionPlansHandler = async () => {

        let response = await subscriptionPlansHandler.getPlansHandler()

        if (response && response.success && Array.isArray(response.data) && response.data.length) {

            let plans = response.data.map((plan) => {

                return {
                    value: plan.id,
                    label: plan.plan_name
                }

            })

            setPlanOptions(plans)
            setPlanDetials(response.data)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in getting Subscription Plans`)
        }
    }



    useEffect(() => {
        getSubscriptionPlansHandler()
    }, [])

    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Team Management",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <div className="popup-container-main popup-container-center">
                <div className="popup-block-ui"></div>
                <div className="side-popup-container" >
                    <Popup_Header />
                    <div className="teamManagement-popup-content" style={{ minHeight: '250px' }}>

                        <div className="content-item" style={{ width: '100%' }}>
                            <TextInput
                                props={{
                                    id: "activatesubscription-customerId",
                                    value: `CUST${customerId}`,
                                    placeholder: '',
                                    setValue: (value) => { },
                                    isIcon: false,
                                    readOnly: true,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Customer",
                                }}
                            />
                        </div>
                        <div className="content-item" style={{ width: '100%' }}>
                            <SelectInput
                                props={{
                                    id: "activatesubscription-plan",
                                    value: PlanId,
                                    placeholder: '',
                                    options: PlanOptions || [],
                                    setValue: (value, label) => HandlePlanSection(value, label),
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Subscription Plan",
                                }}
                            />
                        </div>

                        <div className="content-item" style={{ width: '100%' }}>
                            <TextInput
                                props={{
                                    id: "activatesubscription-users",
                                    value: PlanUsers,
                                    type: 'number',
                                    placeholder: '',
                                    setValue: (value) => setPlanUsers(value),
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Plan Users",
                                }}
                            />
                        </div>
                        <div className="content-item" style={{ width: '100%' }}>
                            <SelectInput
                                props={{
                                    id: "activatesubscription-durationtype",
                                    value: DurationType,
                                    placeholder: '',
                                    options: DurationTypeOptions || [],
                                    setValue: (value, label) => HandleDurationTypeChange(value),
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Duration Type",
                                }}
                            />
                        </div>
                        <div className="content-item" style={{ width: '100%' }}>
                            <TextInput
                                props={{
                                    id: "activatesubscription-duration",
                                    value: Duration,
                                    placeholder: '',
                                    setValue: (value) => HandleDurationChange(value),
                                    isIcon: false,
                                    type: 'number',
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Duration",
                                }}
                            />
                        </div>
                        <div className="content-item" style={{ width: '100%' }}>
                            <TextInput
                                props={{
                                    id: "activatesubscription-plan_amount",
                                    value: `₹ ${PlanAmount}`,
                                    placeholder: '',
                                    setValue: (value) => { },
                                    isIcon: false,
                                    readOnly: true,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Plan Amount",
                                }}
                            />
                        </div>
                        <div className="content-item" style={{ width: '100%' }}>
                            <TextInput
                                props={{
                                    id: "activatesubscription-subscription_amount",
                                    value: `₹ ${SubscriptionAmount}`,
                                    placeholder: '',
                                    setValue: (value) => { },
                                    isIcon: false,
                                    readOnly: true,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Subscription Amount",
                                }}
                            />
                        </div>

                    </div>
                    <Popup_Footer />
                </div>
            </div>
        </>
    )
}

const CustomerView = ({ props }) => {

    const { customerId } = useParams();

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['user'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--vendors' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    const [ApprovalStatusAction, setApprovalStatusAction] = useState('')
    const [ApprovalStatusAlert, setApprovalStatusAlert] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/admin/quick-menu`, { state: { from: location }, replace: true })
            }
            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const customersHandler = new CustomersHandler()

    const HandleCancel = (e) => {

        navigate('/admin/app/customers')
    }

    const [ID, setID] = useState('N/A')
    const [Name, setName] = useState('N/A')
    const [Email, setEmail] = useState('N/A')
    const [Phone, setPhone] = useState('N/A')
    const [Location, setLocation] = useState('N/A')
    const [Country, setCountry] = useState('N/A')
    const [CustomerType, setCustomerType] = useState('N/A')
    const [CustomerLastUpdated, setCustomerLastUpdated] = useState('Last Update : 04:59 PM')
    const [TeamSize, setTeamSize] = useState('N/A')
    const [MainGoal, setMainGoal] = useState('N/A')
    const [ProfileImg, setProfileImg] = useState('')
    const [OnboardingStatus, setOnboardingStatus] = useState('N/A')
    const [RegisterationStatus, setRegisterationStatus] = useState('N/A')

    const [CurrentPlanType, setCurrentPlanType] = useState('N/A')
    const [CurrentPlanName, setCurrentPlanName] = useState('N/A')
    const [CurrentPlanAmount, setCurrentPlanAmount] = useState('N/A')
    const [SubscriptionStatus, setSubscriptionStatus] = useState('N/A')
    const [CustomerApproved, setCustomerApproved] = useState('-1')
    const [CustomerApprovedLabel, setCustomerApprovedLabel] = useState('N/A')

    const [SubscriptionStartDate, setSubscriptionStartDate] = useState('N/A')
    const [SubscriptionEndDate, setSubscriptionEndDate] = useState('N/A')
    const [SubscriptionDuration, setSubscriptionDuration] = useState('N/A')
    const [SubscriptionPaymentMethod, setSubscriptionPaymentMethod] = useState('N/A')
    const [SubscriptionPaymentOrderId, setSubscriptionPaymentOrderId] = useState('N/A')

    const [ShowActivateSubscriptionPopup, setShowActivateSubscriptionPopup] = useState(false)

    const LoadCustomer = async () => {

        let filters = {
            idx: customerId
        }

        let response = await customersHandler.getCustomersHandler(filters)

        if (response && response.success && Array.isArray(response.data) && response.data.length) {

            let customers = response.data

            customers = customers.map((customer) => {

                let subscription_detials = customer.subscription_detials || undefined

                if (subscription_detials) {
                    customer.plan_name = subscription_detials.plan_name
                    customer.plan_amount = subscription_detials.plan_amount

                    customer.subscribed_after_trial = subscription_detials.subscribed_after_trial
                    customer.subscription_trial_period_end_date = subscription_detials.trial_period_end_date
                    customer.subscription_trial_period_start_date = subscription_detials.trial_period_start_date
                    customer.subscription_duration = subscription_detials.duration
                    customer.subscription_duration_type = subscription_detials.duration_type

                    customer.subscription_duration_label = `${subscription_detials.duration || ''} ${subscription_detials.duration_type == '1' ? 'Months' : subscription_detials.duration_type == '2' ? 'Years' : 'Free'}`


                    customer.subscription_amount = subscription_detials.subscription_amount
                    customer.subscription_date_subdcribed = subscription_detials.date_subdcribed
                    customer.subscription_date_unsubdcribed = subscription_detials.date_unsubdcribed

                    customer.subscription_payment_method = subscription_detials.payment_method
                    customer.subscription_payment_order_id = subscription_detials.payment_order_id

                    let subscription_status = -1
                    let subscription_expiry_duration_days = 0

                    customer.subscription_start_date = subscription_detials.subscribed_after_trial == '0' ? parseFloat(subscription_detials.trial_period_start_date) : parseFloat(subscription_detials.date_subdcribed)
                    customer.subscription_end_date = subscription_detials.subscribed_after_trial == '0' ? parseFloat(subscription_detials.trial_period_end_date) : parseFloat(subscription_detials.date_unsubdcribed)

                    if (
                        (
                            subscription_detials.subscribed_after_trial == '0'
                            && subscription_detials.trial_period_end_date
                            && new Date(parseFloat(subscription_detials.trial_period_end_date)).getTime() < new Date().getTime()
                        )
                        || (
                            subscription_detials.subscribed_after_trial == '1'
                            && subscription_detials.date_unsubdcribed
                            && new Date(parseFloat(subscription_detials.date_unsubdcribed)).getTime() < new Date().getTime()
                        )
                    ) {
                        subscription_status = 2
                    } else {

                        let expiry_days = subscription_detials.subscribed_after_trial == '0' ? Utils.getDaysBetweenDates(parseFloat(subscription_detials.trial_period_end_date || 0), new Date()) : Utils.getDaysBetweenDates(parseFloat(subscription_detials.date_unsubdcribed || 0), new Date())

                        subscription_expiry_duration_days = expiry_days
                        subscription_status = 1
                    }

                    customer.subscription_status_label = subscription_status == '2' ? 'Expired' : subscription_status == '1' ? subscription_expiry_duration_days < 15 ? `${subscription_expiry_duration_days}days` : 'Active' : 'Inactive'

                    customer.subscription_status = subscription_status
                    customer.expiry_soon = subscription_expiry_duration_days < 15
                    customer.subscription_expiry_duration_days = subscription_expiry_duration_days



                }

                return customer

            })

            let customer = customers[0]

            console.log(customer, 'customer');

            setID(customer.sno)
            setName(customer.name)
            setEmail(customer.email)
            setPhone(customer.phone)
            setLocation(customer.location)
            setCountry(customer.country)
            setCustomerType(String(customer.builder_type || '').toLocaleUpperCase())
            setTeamSize(customer.team_size)
            setMainGoal(customer.main_goal)


            if (customer.profile_img) setProfileImg(`data:image/png;base64,${customer.profile_img}`)

            if (customer.onboarding_step == '0') setOnboardingStatus('Pending')
            else if (customer.onboarding_step == '1') setOnboardingStatus('Completed')

            if (customer.register_verified == '0') setRegisterationStatus('Pending')
            else if (customer.register_verified == '1') setRegisterationStatus('Completed')

            if (customer.subscribed_after_trial == '0') setCurrentPlanType('Trial')
            else if (customer.subscribed_after_trial == '1') setCurrentPlanType('Subscribed')

            setCustomerApproved(customer.approved)
            if (customer.approved == '0') setCustomerApprovedLabel('Pending')
            else if (customer.approved == '1') setCustomerApprovedLabel('Approved')
            else if (customer.approved == '2') setCustomerApprovedLabel('Rejected')

            setCurrentPlanName(customer.plan_name)
            setCurrentPlanAmount(customer.plan_amount)
            setSubscriptionStatus(customer.subscription_status_label)

            setSubscriptionStartDate(customer.subscription_start_date ? Utils._getLocalDate(parseFloat(customer.subscription_start_date)) : 'N/A')
            setSubscriptionEndDate(customer.subscription_end_date ? Utils._getLocalDate(parseFloat(customer.subscription_end_date)) : 'N/A')
            setSubscriptionDuration(customer.subscription_duration_label)
            setSubscriptionPaymentMethod(customer.subscription_detials.payment_method || 'N/A')
            setSubscriptionPaymentOrderId(customer.subscription_detials.payment_order_id || 'N/A')

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in getting customer detials`)
        }
    }

    const HandleApproveCustomer = async () => {

        let filters = {
            idx: customerId
        }

        let response = await customersHandler.approveCustomerHandler(filters)

        if (response && response.success) {

            setWarningAlert(true)
            setWarningAlertType('success')
            setApiFailedMessage(`Customer Approved Successfully`)

            LoadCustomer()

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Approving Customer`)
        }

    }

    const HandleRejectCustomer = async () => {

        let filters = {
            idx: customerId
        }

        let response = await customersHandler.rejectCustomerHandler(filters)

        if (response && response.success) {

            setWarningAlert(true)
            setWarningAlertType('success')
            setApiFailedMessage(`Customer Rejected Successfully`)

            LoadCustomer()

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Rejecting Customer`)
        }

    }

    const HandleChangeApprovalStatus = async (action) => {
        setApprovalStatusAlert(true)
        setApprovalStatusAction(action)
    }

    const HandleChangeApprovalStatusAction = async (confirmation, type) => {

        setApprovalStatusAction('')
        setApprovalStatusAlert(false)

        if (!confirmation) return

        if (type == 'approve') HandleApproveCustomer()
        else if (type == 'reject') HandleRejectCustomer()

    }

    const HandleActivateSubscription = () => {
        setShowActivateSubscriptionPopup(true)
    }

    useEffect(() => {

        LoadCustomer()

    }, [])


    const CloseActivateSubscriptionPopup = (type) => {

        setShowActivateSubscriptionPopup(false)
    }

    return (
        <>

            {ShowActivateSubscriptionPopup ?
                <ActivateSubscriptionPopup props={{
                    type: "create",
                    heading: "Activate Subscription",
                    callback: LoadCustomer,
                    close: CloseActivateSubscriptionPopup,
                    customerId: customerId
                }} />

                : ''}



            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Vendors",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            {ApprovalStatusAlert ?

                <AlertPopup
                    props={{
                        type: "active",
                        actionBtnOption: { icon: '', label: "Yes" },
                        heading: `Customer ${ApprovalStatusAction} Confirmation`,
                        message: `Are you sure you want to ${ApprovalStatusAction} this Customer?`,
                        callback: (confirmation) => HandleChangeApprovalStatusAction(confirmation, ApprovalStatusAction)
                    }} />

                : null}



            <div className="customer-view-main">

                <div className="customer-content-main">

                    <div className="customer-section-main customer-header-main">

                        <div className=" header-content">
                            <div className="header-left">
                                <div className="header-back-btn"
                                    onClick={(e) => HandleCancel(e)}
                                    dangerouslySetInnerHTML={{ __html: Icons.general.back_arrow }}
                                ></div>
                                <div className="header-profile-logo">
                                    <img src={ProfileImg || Images.User} alt="" />
                                </div>
                                <div className="header-detials">
                                    <div className="header-name">{Name}</div>

                                    <div className="header-id">ID: CUST{ID}</div>
                                    <div className="header-lastupdated">{CustomerLastUpdated}</div>
                                </div>
                            </div>
                            <div className="header-right">
                                <div className="header-action-btn header-quick-btns header-edit-btn">
                                    <div className="label">Edit</div>
                                </div>
                                <div className="header-action-btn header-option-btn">
                                    <div className="icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.more_btn }}
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="customer-section-main customer-detials-main">

                        <div className="section-content">

                            <div className="section-header">
                                <div className="title">Account Detials</div>
                            </div>

                            <div className="detials-content-main">

                                <div className="detials-content-section">


                                    <div className="detials-content-row">
                                        <div className="label">Owner Name</div>
                                        <div className="value">{Name}</div>
                                    </div>

                                    <div className="detials-content-row">
                                        <div className="label">Email</div>
                                        <div className="value">{Email}</div>
                                    </div>

                                    <div className="detials-content-row">
                                        <div className="label">Phone</div>
                                        <div className="value">{Phone}</div>
                                    </div>
                                    <div className="detials-content-row">
                                        <div className="label">Location</div>
                                        <div className="value">{Location}</div>
                                    </div>
                                    <div className="detials-content-row">
                                        <div className="label">Country</div>
                                        <div className="value">{Country}</div>
                                    </div>



                                </div>
                                <div className="detials-content-section">

                                    <div className="detials-content-row">
                                        <div className="label">CustomerType</div>
                                        <div className="value">{CustomerType}</div>
                                    </div>

                                    <div className="detials-content-row">
                                        <div className="label">TeamSize</div>
                                        <div className="value">{TeamSize}</div>
                                    </div>
                                    <div className="detials-content-row">
                                        <div className="label">MainGoal</div>
                                        <div className="value">{MainGoal}</div>
                                    </div>
                                    <div className="detials-content-row">
                                        <div className="label">Registeration Status</div>
                                        <div className="value">{RegisterationStatus}</div>
                                    </div>
                                    <div className="detials-content-row">
                                        <div className="label">Onboarding Status</div>
                                        <div className="value">{OnboardingStatus}</div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div className="customer-section-main customer-detials-main">

                        <div className="section-content">

                            <div className="section-header">
                                <div className="title">Subscription Detials</div>
                            </div>

                            <div className="detials-content-main">

                                <div className="detials-content-section">


                                    <div className="detials-content-row">
                                        <div className="label">PlanType</div>
                                        <div className="value">{CurrentPlanType}</div>
                                    </div>
                                    <div className="detials-content-row">
                                        <div className="label">PlanName</div>
                                        <div className="value">{CurrentPlanName}</div>
                                    </div>
                                    <div className="detials-content-row">
                                        <div className="label">PlanAmount</div>
                                        <div className="value">₹{CurrentPlanAmount}</div>
                                    </div>
                                    <div className="detials-content-row">
                                        <div className="label">Subscription Status</div>
                                        <div className="value">{SubscriptionStatus}</div>
                                    </div>
                                    <div className="detials-content-row">
                                        <div className="label">Approval Status</div>
                                        <div className="value">{CustomerApprovedLabel}</div>
                                    </div>

                                </div>

                                <div className="detials-content-section">


                                    <div className="detials-content-row">
                                        <div className="label">Payment Method</div>
                                        <div className="value">{SubscriptionPaymentMethod}</div>
                                    </div>
                                    <div className="detials-content-row">
                                        <div className="label">Payment OrderId</div>
                                        <div className="value">{SubscriptionPaymentOrderId}</div>
                                    </div>

                                    <div className="detials-content-row">
                                        <div className="label">Duration</div>
                                        <div className="value">{SubscriptionDuration}</div>
                                    </div>
                                    <div className="detials-content-row">
                                        <div className="label">StartDate</div>
                                        <div className="value">{SubscriptionStartDate}</div>
                                    </div>
                                    <div className="detials-content-row">
                                        <div className="label">EndDate</div>
                                        <div className="value">{SubscriptionEndDate}</div>
                                    </div>



                                </div>

                            </div>


                        </div>
                    </div>

                    <div className="customer-section-main customer-detials-main">

                        <div className="section-content">

                            <div className="section-header">
                                <div className="title">Actions</div>
                            </div>

                            <div className="actions-detials-content-main">

                                {CustomerApproved == '0' || CustomerApproved == '2' ?

                                    <div
                                        className="actions-button approve-customer-btn"
                                        onClick={(e) => HandleChangeApprovalStatus('approve')}
                                    >
                                        <div className="label">Approve Customer</div>
                                    </div>

                                    : ''}

                                {CustomerApproved == '1' ?

                                    <div
                                        className="actions-button reject-customer-btn"
                                        onClick={(e) => HandleChangeApprovalStatus('reject')}
                                    >
                                        <div className="label">Reject Customer</div>
                                    </div>

                                    : ''}



                                <div
                                    className="actions-button activate-subscription-btn"
                                    onClick={(e) => HandleActivateSubscription()}
                                >
                                    <div className="label">Activate subscription</div>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </div >
        </>
    )

}

export default CustomerView;