import AdminUserHandler from '../Handlers/AdminUser/AdminUser'
import TeamManagementHandler from '../Handlers/TeamManagement/TeamManagement'

class StoreHandler {

    constructor({ dataStack, store, dispatch, updateState }) {

        this.store = store || {}
        this.dataStack = dataStack || []
        this.dispatch = dispatch
        this.updateState = updateState

        this.adminUserHandler = new AdminUserHandler()
        this.teamManagementHandler = new TeamManagementHandler()


        // this.init()
    }

    async init() {

        return new Promise((resolve, reject) => {


            this.dataStack.forEach(async (item, i) => {

                if (item == 'user') return resolve(await this.loadUserData())
                if (item == 'team_users') return resolve(await this.loadUsersData())

            })

        })


    }

    async loadUserData() {

        let response = await this.adminUserHandler.getProfileHandler()

        if (response && response.success) {

            this.dispatch(this.updateState({
                type: "SET_USER",
                payload: { user: response.data }
            }))

        }

        return true
    }


    async loadUsersData() {


        let response = await this.teamManagementHandler.getTeamUsersHandler()

        if (response && response.success) {

            this.dispatch(this.updateState({
                type: "SET_USERS",
                payload: {
                    team_users: response.data
                }
            }))

        }

        return true
    }

}

export default StoreHandler;